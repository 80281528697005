import React, { useContext, useEffect, useRef, useState } from "react";
import { Chip, Divider, Tooltip } from "@mui/material";
import { AppContext } from "../../../../../context/context";
import Avatar from "../../../../../images/Avatar.svg";
import { Label } from "../../../../../stories/Label/Label";
import { getFileName, toIsoDate } from "../../../../../utils/UtilFunctions";
import {
  GetChannelMessages,
  GetMessageDataById,
} from "../../../../../queries/chat/feeds/list";
import {
  GetChannelMessagesData,
  GetChannelMessagesVars,
  GetMessageData,
  GetMessageDataVariables,
} from "../../Feeds/Types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  EMPTY_STRING,
  ImageTypes,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../../utils/Enum.types";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import localizedFormat from "dayjs/plugin/localizedFormat";
import axios from "axios";
import useChannels from "../../CustomHooks/useChannels";

import dayjs from "dayjs";
import useIndividualStudentId from "../../CustomHooks/useIndividualStudentId";
import { useStyles } from "../../../../../styles/TooltipStyles";

import Delete from "../../../../../images/Delete.svg";
import Edit from "../../../../../images/EditProfile.svg";

import More from "../../../../../images/DownArrow.svg";
import DeleteModal from "../../../../Modals/DeleteModal";
import { DeleteChannelMessage } from "../../../../../queries/chat/mutations";
import { msgType } from "../../../../../utils/Types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import ReactModal from "react-modal";
import SendMessage from "./SendMessage";
import { MessageModalStyles } from "../../../../styles/ModalStyles";
import useServerDateandTime from "../../../../../customhooks/useServerDateandTimeWithoutToken";

interface MessageNode {
  id: number;
  msg_content: string;
  msg_date: string;
  created_at: string;
  sent_user_id: number;
  msg_media_content: string;
  msg_header: string;
  url: string;
  msg_channel_id: number;
}
interface MessageEdge {
  node: MessageNode;
  cursor: string;
}
interface MessageData {
  url: string;
  mediaContent: string;
  id: number;
  createdTime: string;
  downloadKey: string;
  sent_user_id: number;
}
export interface UpdateMessageType {
  message: string;
  id: number;
}
dayjs.extend(localizedFormat);

interface Prop {
  searchMessage: string;
  startDate: string;
  endDate: string;
  channel: ChannelQueryType;
}
const MessageList = ({ searchMessage, endDate, startDate, channel }: Prop) => {
  const messageListRef = useRef<HTMLUListElement>(null);

  const { token } = useToken();
  const [messageModal, setMessageModal] = useState(false);

  const { instId, entryId } = useParams();
  const { InstFormData } = useInstDetails();
  const { serverDate } = useServerDateandTime();

  const { data } = useChannels(channel);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  // eslint-disable-next-line
  const [mediaContent, setMediaContent] = useState("");
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const { state } = useContext(AppContext);
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [userList, setUserList] = useState<Map<number, string>>(new Map());
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(true);

  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const tooltipclasses = useStyles();
  const { channelId: individualChannelId } = useIndividualStudentId(
    state.studentId
  );
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const { user_details } = useLoggedInUserDetails();
  const [DeleteMessage, { loading: DeleteLoading }] = useMutation(
    DeleteChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesData, GetChannelMessagesVars>(
      GetChannelMessages
    );
  const [updateMessage, setUpdateMessage] = useState<UpdateMessageType>({
    message: "",
    id: 0,
  });
  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://hnlm1fiypb.execute-api.ap-south-1.amazonaws.com/downloadObjectFromESandeshBucket?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        if (response.data !== null)
          axios.get(response.data, { responseType: "blob" }).then(() => {
            setDownloadUrl((prevDownloadUrl) =>
              prevDownloadUrl.map((messageData) => {
                if (getFileName(mediaContent) === messageData.downloadKey) {
                  return { ...messageData, url: response.data };
                } else return messageData;
              })
            );
          });
      })
      .catch((err) => console.log(err));
  };

  const channelId =
    channel === ChannelQueryType.INDIVIDUAL
      ? individualChannelId
      : data && data.GetChannels.length
      ? data.GetChannels[0].id
      : 0;

  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res) {
                  if (res.first_name)
                    return [
                      res.id,
                      `${
                        res.first_name +
                        " " +
                        res.middle_name +
                        " " +
                        res.last_name
                      }`,
                    ];
                  else if (res.emp_name) return [res.id, `${res.emp_name}`];
                  else return [res.id, `${res.std_name}`];
                } else return [0, ""];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);
  useEffect(() => {
    if (channel) {
      setAllMessages([]);
      setDownloadUrl([]);
    }
  }, [channel]);

  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");

        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);
    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;
        getDownloadUrl(message.id, mediaContent);
      });
    };
    // if (messageDataArray.some((message) => message.mediaContent !== ""))
    fetchDownloadUrls();
  }, [allMessages, channel, InstFormData.inst_name]);

  useEffect(() => {
    if (data && token && instId) {
      setAllMessages([]);
      setDownloadUrl([]);
      GetMessages({
        variables: {
          token,
          inst_id: instId ? instId : state.InstId.toString(),
          input: {
            query_type:
              channel === ChannelQueryType.INDIVIDUAL ||
              channel === ChannelQueryType.ENTRYLEVEL
                ? MsgChannelType.MSGS_BY_CHANNEL_ID
                : channel === ChannelQueryType.ANNOUNCEMENTS
                ? MsgChannelType.ANNOUNCEMENTS
                : MsgChannelType.MY_CAMPUS_FEEDS,
            channel_id: channelId,
            end_date: endDate ? toIsoDate(endDate) : toIsoDate(serverDate),
            start_date: startDate
              ? toIsoDate(startDate)
              : toIsoDate(serverDate),
            entry_id: entryId!,
          },
          direction: Direction.ASC,
          last: 10,
          after: null,
          sortBy: SortBy.CREATED_AT,
          messageContent: searchMessage,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [
    token,
    mediaContent,
    MessagesData,
    GetMessages,
    data,
    channelId,
    individualChannelId,
    searchMessage,
    startDate,
    endDate,
    channel,
  ]);

  useEffect(() => {
    if (MessagesData && !loading) {
      const newMessages = MessagesData.GetChannelMessages.edges;

      if (startCursor) {
        const updatedNewMessages = newMessages.map((newMessage) => {
          const filteredMessage = allMessages.find(
            (message) => message.node.id === newMessage.node.id
          );
          if (filteredMessage) {
            return {
              ...newMessage,
              node: {
                ...newMessage.node,
              },
            };
          }
          return newMessage;
        });
        setAllMessages(updatedNewMessages);
      } else {
        setAllMessages(newMessages);
      }

      const pageInfo = MessagesData.GetChannelMessages.pageInfo;
      setStartCursor(pageInfo.startCursor);
      setHasPreviousPage(pageInfo.hasPreviousPage);
    } // eslint-disable-next-line
  }, [MessagesData, loading, startCursor]);

  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;

    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: ROWS_PER_PAGE,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData
                ? MessagesData.GetChannelMessages.totalCount
                : 0,
            },
          };
        },
      });
    }
  };

  const HandleDelete = (id: Number) => {
    DeleteMessage({
      variables: {
        token,
        msg_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelMessages,
          variables: {
            token,
            inst_id: instId ? instId : state.InstId.toString(),
            input: {
              query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
              channel_id: channelId,
              start_date: toIsoDate(DEFAULT_TIME),
              end_date: toIsoDate(DEFAULT_TIME),
            },
            direction: Direction.ASC,
            last: 10,
            after: null,
            sortBy: SortBy.CREATED_AT,
            messageContent: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
    setDeleteModal(!deleteModal);
  };

  return (
    <>
      <div className="chat-channel-messages" onScroll={handleScroll}>
        <ul ref={messageListRef}>
          {Object.entries(byDate)?.map(([date, messages], index) => (
            <React.Fragment key={index}>
              <Divider>
                <Chip label={date} />
              </Divider>
              {messages.map((message, index) => {
                const parsedContent = parseMessageContent(
                  message.node.msg_content
                );
                const isImage = ImageTypes.includes(
                  message.node.msg_media_content.split(".")[
                    message.node.msg_media_content.split(".").length - 1
                  ]
                );
                const isPdf = message.node.msg_media_content.includes(".pdf");
                const isExcel =
                  message.node.msg_media_content.includes(".xls") ||
                  message.node.msg_media_content.includes(".xlsx");
                const isVideo = message.node.msg_media_content.includes(".mp4");
                const id = state.claims?.EMPLOYEE
                  ? state.empLoginId
                  : state.studentId;
                const isSentUser = id === message.node.sent_user_id;

                const combinedById = downLoadUrl.reduce((result, item) => {
                  if (!result[item.id]) {
                    result[item.id] = [item];
                  } else {
                    result[item.id].push(item);
                  }
                  return result;
                }, {} as { [id: number]: MessageData[] });

                return (
                  <li
                    key={index}
                    className={`chat-channel-messages--list${
                      isSentUser ? "--sender" : ""
                    }--li`}
                  >
                    <div
                      className={`chat-channel-messages--list${
                        isSentUser ? "--sender" : ""
                      }`}
                    >
                      {!isSentUser && (
                        <img
                          src={Avatar}
                          alt="/"
                          className="chat-channel-messages--image"
                        />
                      )}
                      <div>
                        {!isSentUser && (
                          <Label variant="LabelBold">
                            {userList.get(message.node.sent_user_id)}
                          </Label>
                        )}
                        {state.claims && state.claims.EMPLOYEE ? (
                          <Tooltip
                            onClick={() =>
                              setEnableToolTipModal(!enableToolTipModal)
                            }
                            classes={{
                              tooltip: tooltipclasses.studentOptions,
                            }}
                            placement="right"
                            title={
                              enableToolTipModal && (
                                <>
                                  <ul className="feeds__messages--list--tooltip--ul">
                                    <li
                                      onClick={() => {
                                        setMessageModal(!messageModal);
                                        setUpdateMessage({
                                          message: message.node.msg_content,
                                          id: message.node.id,
                                        });
                                      }}
                                      className="studentlist__table--more--fee"
                                    >
                                      <img src={Edit} alt="/" />
                                      <span> Edit Message</span>
                                    </li>
                                    <li
                                      onClick={() => {
                                        setDeleteModal(!deleteModal);
                                        setDeleteId(message.node.id);
                                      }}
                                      className="studentlist__table--more--fee"
                                    >
                                      <img src={Delete} alt="/" />
                                      <span> Delete Message</span>
                                    </li>
                                  </ul>
                                </>
                              )
                            }
                          >
                            <span className="feeds__messages--list--time">
                              {dayjs(message.node.created_at).format("LT")}
                              <img src={More} alt="/" />
                            </span>
                          </Tooltip>
                        ) : (
                          <span className="feeds__messages--list--time">
                            {dayjs(message.node.created_at).format("LT")}
                          </span>
                        )}

                        {message.node.msg_content && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: parsedContent,
                            }}
                          />
                        )}
                        {combinedById[message.node.id]?.map(
                          ({ mediaContent, url }, urlIndex) => {
                            if (isImage) {
                              return (
                                <a key={urlIndex} href={url}>
                                  <img
                                    src={url}
                                    alt="Loading...."
                                    className="chat-channel-messages--file-image"
                                  />
                                </a>
                              );
                            } else if (isPdf || isExcel) {
                              return (
                                <a
                                  key={urlIndex}
                                  href={url}
                                  onClick={() => handleFileDownload(url)}
                                >
                                  {getFileName(mediaContent)}
                                </a>
                              );
                            } else if (isVideo) {
                              return (
                                <video
                                  key={urlIndex}
                                  width="320"
                                  height="240"
                                  controls
                                  src={url}
                                >
                                  {mediaContent}
                                  Your browser does not support the video tag.
                                </video>
                              );
                            } else {
                              return null;
                            }
                          }
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          ))}
        </ul>
      </div>
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <ReactModal
        isOpen={messageModal}
        ariaHideApp={false}
        style={MessageModalStyles}
      >
        <SendMessage
          setModal={setMessageModal}
          Channel={
            state.studentId
              ? ChannelQueryType.INDIVIDUAL
              : ChannelQueryType.ENTRYLEVEL
          }
          setMessage={setMessage}
          updateMessage={updateMessage}
        />
      </ReactModal>
      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.CREATE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={DeleteLoading} />
    </>
  );
};

export default MessageList;
