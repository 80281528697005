import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Autocomplete, FormControlLabel, TextField } from "@mui/material";
import Modal from "react-modal";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Close from "../../../../../images/Close.svg";
import { useMutation } from "@apollo/client";

import { InstitutionCustomStyles } from "../../../../styles/ModalStyles";

import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  ColumnVisibilityFor,
  Direction,
  Operation,
  SortBy,
  StudentAcdType,
  StudentReportType,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import {
  StudentsTitlesType,
  TableHeaderProps,
  responseType,
} from "../../../../../utils/Types";
import { StudentEdges } from "../../../../../customhooks/useStudentByNodeApi";
import { msgType } from "../../../../../utils/FormTypes";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  licenseTypes,
} from "../../../../../utils/constants";
import useAcdStudentsData from "../../../../../customhooks/useAcdStudentsData";
import Navbar from "../../../../Layouts/Navbar";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../../customhooks/useDropDown";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import useToken from "../../../../../customhooks/useToken";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { UpdateStudentLoginStatus } from "../../../../../queries/students/mutations";
import { EnableStudentLoginStatusWithNewEmail } from "../../../../../queries/byTime";
import useActiveAcademicYear from "../../../../../customhooks/useActiveAcademicYear";
import { GetAcdStudents } from "../../../../../queries/students/list";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { AntSwitch } from "../../../../../pages/Switch";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import { Keys } from "../../../../../utils/Enum.keys";
import useStudentTableJson from "../../../../../customhooks/useStudentTableJson";
import { Button } from "../../../../../stories/Button/Button";
import StudentTotalCount from "../Components/StudentsTotalCount";
import LoadingModal from "../../../../Modals/LoadingModal";
import MessageModal from "../../../../../utils/MessageModal";
import { FetchStudentStatusClassName } from "./List";
import Sidebar from "../../../../Layouts/Sidebar";
const { Students } = require("../../../../../utils/title.json");
interface Props {
  queryType: StudentReportType;
}
const StudentList = ({ queryType }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { token } = useToken();
  const { instId } = useParams();
  const saveRef = useRef<HTMLButtonElement>(null);
  const dataClasses = useDataGridStyles();
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_LOGIN_STATUS,
  });
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [mobileNo, setMobileNo] = useState("");
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [searchData, setSearchData] = useState("");
  const [studentId, setStudentId] = useState(0);
  const [eMail, setEmail] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();
  const { AcademicsStudentData } = useAcdStudentsData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    queryType
  );

  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateStudentLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );
  const [UpdateEmail, { loading }] = useMutation(
    EnableStudentLoginStatusWithNewEmail,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleNumberChange = (mobileNo: string) => {
    setMobileNo(mobileNo);
  };
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { activeAcademicYearData } = useActiveAcademicYear();

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setSearchData("");
  };

  const stdData = AcademicsStudentData.data?.GetAcdStudents.edges.find(
    (data) => data.node.id === studentId
  )?.node;
  const fullName = stdData ? `${stdData?.std_name}` : EMPTY_STRING;
  const branchName = stdData ? stdData?.branch.branch_desc : EMPTY_STRING;
  const className = stdData ? stdData?.class.class_desc : EMPTY_STRING;
  const handleSubmit = (student_id: number) => {
    const flagData = AcademicsStudentData.data?.GetAcdStudents.edges.find(
      (data) => data.node.id === student_id
    )?.node;
    EnableLogin({
      variables: {
        token,
        student_id,
        inst_id: instId!,
        user_details,
        login_status: !flagData?.is_login_created,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,

            after: null,
            first: ROWS_PER_PAGE,
            input: {
              ids: [Number(instId!)],
              std_query_type: StudentAcdType.BY_INST_ID,
              str_data: [""],
            },
            name: "",
            token,
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.STD_NAME,
              },
            ],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "Student Login Enabled Successfully"
            : "Student Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleUpdate = () => {
    UpdateEmail({
      variables: {
        token,
        student_id: studentId,
        login_data: {
          std_email: eMail,
          std_mobile: mobileNo.length === 12 ? `+${mobileNo}` : EMPTY_STRING,
        },
        inst_id: instId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            after: null,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
            first: ROWS_PER_PAGE,
            name: "",
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [Number(instId!)],
              std_query_type: StudentAcdType.BY_INST_ID,
              str_data: [""],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Student Details Updated Successfully",
          operation: Operation.UPDATE,
        });
        setEmailModal(!emailModal);
        setEmail(EMPTY_STRING);
      }
    });
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (AcademicsStudentData?.data && !AcademicsStudentData?.loading) {
      const newData = AcademicsStudentData?.data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        AcademicsStudentData?.data.GetAcdStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [AcademicsStudentData?.data, AcademicsStudentData?.loading]);

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        return (
          <>
            <FormControlLabel
              label=""
              control={
                <AntSwitch
                  checked={params.row.is_login_created}
                  onClick={() => {
                    handleSubmit(params.row.std_id);
                    setStudentId(params.row.std_id);
                    setMobileNo(params.row.std_mobile);
                  }}
                />
              }
              labelPlacement="start"
            />
          </>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_dob: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY!,
      branch: USE_BRANCH_KEY!,
      class: USE_CLASS_KEY!,
      sem: USE_SEMESTER_KEY!,
      sec: USE_SECTION_KEY!,
      status: false,
      category: false,
    });
  useEffect(() => {
    if (AcademicsStudentData?.data && !AcademicsStudentData?.loading) {
      const newData = AcademicsStudentData.data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_sex: node.std_sex,
            std_email: node.std_email,
            std_dob: toStandardDate(node.std_dob),
            is_login_created: node.is_login_created,
            dept: node.dept.dept_desc,
            branch: node.branch.branch_desc,
            class: node.class.class_desc,
            sem: node.semester.sem_desc,
            sec: node.section.section_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_sex: node.std_sex,
            std_dob: toStandardDate(node.std_dob),

            std_email: node.std_email,
            is_login_created: node.is_login_created,
            dept: node.dept.dept_desc,
            branch: node.branch.branch_desc,
            class: node.class.class_desc,
            sem: node.semester.sem_desc,
            sec: node.section.section_desc,
          }))
        );
      }
      setEndCursor(AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [AcademicsStudentData.data, AcademicsStudentData.loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          AcademicsStudentData.fetchMore({
            variables: {
              first: ROWS_PER_PAGE,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: AcademicsStudentData.data
                    ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_LOGIN_STATUS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_LOGIN_STATUS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="std-enable-login">
        <Sidebar />

        <div className="std-enable-login__frame">
          <Title>Login Status</Title>

          <form className="row g-0 std-enable-login__options">
            <div className="col-1 studentlist__select-flex studentlist__search">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchData(e.target.value)
                }
                value={searchData}
                onKeyDown={handleFormEvent}
              />
            </div>
            {USE_DEPARTMENT_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, departmentSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (departmentSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue);
                    } else {
                      setDepartmentSelected(null);
                    }
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      InputLabelProps={{ shrink: true }}
                      id="outlined Departments"
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (branchSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue);
                    } else {
                      setBranchSelected(null);
                    }
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (classSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue);
                    } else {
                      setClassSelected(null);
                    }
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={semesterDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, semesterSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (semesterSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(null);
                    }
                  }}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue);
                    } else {
                      setSemesterSelected(null);
                    }
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={sectionDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, sectionSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (sectionSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue);
                    } else {
                      setSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}

            <div className="col"></div>
            <div className="col-1 flex-end">
              <Button
                mode="clear"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  clear();
                }}
              />
            </div>
          </form>
          <div className={`std-enable-login__tableblock ${dataClasses.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              disableRowSelectionOnClick
              hideFooter
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </div>
          <div className="row g-0">
            <div className="col flex-end">
              <StudentTotalCount
                totalCount={
                  AcademicsStudentData.data?.GetAcdStudents.totalCount!
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={emailModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="row g-0">
              <Title variant="subtitle1">
                {Students.BasicDetails.Titles.map(
                  (title: StudentsTitlesType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>{title.Email}</React.Fragment>
                    );
                  }
                )}
              </Title>
            </div>

            <TextField
              className="std-enable-login__textfield"
              label="Name"
              value={fullName}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="std-enable-login__textfield"
              label={branchLabel}
              value={branchName}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="std-enable-login__textfield"
              label={classLabel}
              value={className}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="std-enable-login__textfield--email"
              label="Update E-mail"
              autoFocus
              value={eMail}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <PhoneInput
              country={"in"}
              value={mobileNo}
              onChange={handleNumberChange}
              inputProps={{
                required: true,
                name: "Phone No.",
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  saveRef.current?.focus();
                }
              }}
            />
            <div className="frame-space">
              <Button
                type="submit"
                mode="save"
                buttonref={saveRef!}
                onClick={handleUpdate}
              />

              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  setEmailModal(!emailModal);
                  setEmail(EMPTY_STRING);
                }}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setEmailModal(!emailModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || EnableLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentList;
