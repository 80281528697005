import React, { useState, SyntheticEvent, useEffect, useContext } from "react";

import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { AppContext } from "../../../../../context/context";
import { Label } from "../../../../../stories/Label/Label";
import Input from "../../../../../stories/Input/Input";
import useToken from "../../../../../customhooks/useToken";
import { Select } from "../../../../../stories/Select/Select";
import {
  Direction,
  GetSwConfigVariablesVars,
  Operation,
  SortBy,
  SwConfigQueryType,
} from "../../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  licenseTypes,
} from "../../../../../utils/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../../styles/Accordion";
import { AntSwitch } from "../../../../../pages/Switch";
import { GetSwConfigVariables } from "../../../../../queries/institution/configuration/query/SoftwareConfig";
import {
  GetSwConfigVariablesData,
  GlobalPageConfigData,
  UpdateInstTimeZoneAndBdayMsgTimeData,
  UpdateInstTimeZoneAndBdayMsgTimeVars,
} from "../../../../../utils/Query.Types";
import { UpdateSwConfigVariables } from "../../../../../queries/configuration/query/mutations/update";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import { msgType } from "../../../../../utils/Types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import Sidebar from "../../../../Layouts/Sidebar";
import Navbar from "../../../../Layouts/Navbar";
import {
  ReCalculateSummaryCounts,
  UpdateInstTimeZoneAndBdayMsgTime,
} from "../../../../../queries/institution/mutations/update";
import useActiveAcademicYear from "../../../../../customhooks/useActiveAcademicYear";
import Time from "../../../../common/Time";
import { GetTimeZone } from "../../../../../queries/byTime";
import { PageInfo } from "../../Feeds/Types";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import DownArrow from "../../../../../images/DownArrow.svg";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import { InstDetailsByNodeId } from "../../../../../queries/institution/queries/byId";
import useInstModules from "../../../../../customhooks/useInstModules";

interface MsgSwTimeZoneEdge {
  node: {
    id: number;
    timezone: string;
  };
  cursor: string;
}

interface GetTimeZoneData {
  GetTimeZone: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: MsgSwTimeZoneEdge[];
  };
}
interface GetTimeZoneVars {
  token: string;
  after: string | null;
  first: number;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  name: string;
}

const InstConfiguration = () => {
  const { instId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const textClasses = formAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();

  const [expanded, setExpanded] = useState<string | false>("");
  const [endCursor, setEndCursor] = useState("");
  const [searchTimeZone, setSearchTimeZone] = useState("");

  const [mins, setMins] = useState("00");

  const [hrs, setHrs] = useState("00");

  const [hasNextPage, setHasNextPage] = useState(true);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );

  const [timeZones, setTimeZones] = useState<MsgSwTimeZoneEdge[]>([]);

  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  const { user_details } = useLoggedInUserDetails();

  const { activeAcademicYearData } = useActiveAcademicYear();

  const { InstDetails } = useInstDetails();

  const [UpdateTimeZone] = useMutation<
    UpdateInstTimeZoneAndBdayMsgTimeData,
    UpdateInstTimeZoneAndBdayMsgTimeVars
  >(UpdateInstTimeZoneAndBdayMsgTime, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetTimeZones, { data, loading: timeZoneLoading, fetchMore }] =
    useLazyQuery<GetTimeZoneData, GetTimeZoneVars>(GetTimeZone, {
      variables: {
        after: null,
        first: ROWS_PER_PAGE,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.TIME_ZONE,
        },
        token,
        name: searchTimeZone,
      },
    });
  const { InstModuleData } = useInstModules();
  const accountsFlag =
    InstModuleData &&
    InstModuleData.data &&
    InstModuleData.data.GetMstInstModules.inst_module_details?.find(
      (res) => res.eduate_module_details.Name === "ACCOUNTS"
    )?.is_module_enabled;
  useEffect(() => {
    if (token) GetTimeZones();
  }, [token, GetTimeZones]);
  const [UpdateInstConfig, { loading: updationLoading }] = useMutation(
    UpdateSwConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [RecalculateCount, { loading: recalculateLoading }] = useMutation(
    ReCalculateSummaryCounts,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetSwModuleDetails, { loading }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (data && !timeZoneLoading) {
      const newData = data.GetTimeZone.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = timeZones.find(
            ({ node }) => node.id && node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setTimeZones(updatedNewData);
      } else {
        setTimeZones(newData);
      }
      setEndCursor(data.GetTimeZone.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const handleUpdateSwConfig = () => {
    UpdateInstConfig({
      variables: {
        token,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.id,
          config_key: pageSwDetail.config_key,
          config_boolean_value: pageSwDetail.config_boolean_value,
          config_integer_value: pageSwDetail.config_integer_value,
          config_double_value: pageSwDetail.config_double_value,
          config_string_value: pageSwDetail.config_string_value,
          config_string_value_2: pageSwDetail.config_string_value_2,
          inst_id: instId || state.InstId,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigVariables,
          variables: {
            token,
            inst_id: instId! || state.InstId,
            input: {
              config_query_type: SwConfigQueryType.INST_COMPLETE_CONFIG,
              str_value: EMPTY_STRING,
              int_value: 0,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Global Configuration Saved Successfully",
          operation: Operation.CREATE,
        });
      }
      setExpanded(false);
    });
  };

  useEffect(() => {
    if (
      InstDetails.data &&
      InstDetails.data.nodes.length &&
      !InstDetails.loading
    ) {
      if (
        InstDetails.data.nodes[0].bd_msg_hours > 0 &&
        InstDetails.data.nodes[0].bd_msg_hours < 10
      ) {
        setHrs(`0${InstDetails.data.nodes[0].bd_msg_hours.toString()}`);
      }
      if (
        InstDetails.data.nodes[0].bd_msg_hours > 0 &&
        InstDetails.data.nodes[0].bd_msg_hours >= 10
      ) {
        setHrs(`${InstDetails.data.nodes[0].bd_msg_hours.toString()}`);
      }
      if (
        InstDetails.data.nodes[0].bd_msg_mins > 0 &&
        InstDetails.data.nodes[0].bd_msg_mins < 10
      ) {
        setMins(`0${InstDetails.data.nodes[0].bd_msg_mins.toString()}`);
      }
      if (
        InstDetails.data.nodes[0].bd_msg_mins > 0 &&
        InstDetails.data.nodes[0].bd_msg_mins >= 10
      ) {
        setMins(`${InstDetails.data.nodes[0].bd_msg_mins.toString()}`);
      }
    }
  }, [InstDetails.data, InstDetails.loading]);

  const handleChangeValue = (
    key: string,
    type: string,
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR2") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const groupedPageSwDetails: { [key: string]: GlobalPageConfigData[] } =
    pageSwDetails.reduce((groups, item) => {
      const { config_key_used_for_module } = item;
      if (config_key_used_for_module === "RECEIPT-PRINT" && !accountsFlag) {
        return groups;
      }

      if (!groups[config_key_used_for_module]) {
        groups[config_key_used_for_module] = [];
      }

      groups[config_key_used_for_module].push(item);
      return groups;
    }, {} as { [key: string]: GlobalPageConfigData[] });

  useEffect(() => {
    if (token && (instId || state.InstId)) {
      GetSwModuleDetails({
        variables: {
          token,
          inst_id: instId! || state.InstId,
          input: {
            config_query_type: SwConfigQueryType.INST_COMPLETE_CONFIG,
            str_value: "",
            int_value: 0,
          },
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetSwConfigVariables);
        }
      });
    }
  }, [token, instId, state.InstId, GetSwModuleDetails]);

  const handleRecalculate = () => {
    RecalculateCount({
      variables: {
        token,
        inst_id: instId! || state.InstId,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.ReCalculateSummaryCounts) {
        setMessage({
          flag: true,
          message: "Successfully recalculated summary counts",
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetTimeZone.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = timeZones.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setTimeZones(updatedNewData);
      } else {
        setTimeZones(newData);
      }
      setEndCursor(data.GetTimeZone.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const timeZoneOptions = timeZones.map((time_zone) => time_zone.node.timezone);
  const handleTimeZone = () => {
    UpdateTimeZone({
      variables: {
        input: {
          bd_msg_hours: Number(hrs),
          bd_msg_mins: Number(mins),
          inst_time_zone: selectedTimeZone
            ? selectedTimeZone
            : InstDetails.data && InstDetails.data.nodes.length
            ? InstDetails.data.nodes[0].inst_time_zone
            : "",
        },
        inst_id: instId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: InstDetailsByNodeId,
          variables: {
            ids: [instId ? Number(instId) : Number(state.InstId)],
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateInstTimeZoneAndBdayMsgTime) {
        setMessage({
          flag: true,
          message: "Time zone added sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !timeZoneLoading) {
        fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetTimeZone.edges;
            const pageInfo = fetchMoreResult.GetTimeZone.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetTimeZone.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetTimeZone: {
                edges: [...timeZones, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetTimeZone.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="inst-config">
        <Sidebar />
        <div className="inst-config__frame">
          <>
            <div className={"edu-global-configuration"}>
              <div className="edu-global-configuration__title">
                <Title>Institution Configuration</Title>
              </div>
              <div className="edu-global-configuration__block">
                <div className="edu-global-configuration__block--details">
                  {Object.keys(groupedPageSwDetails).map(
                    (label: string, index: number) => {
                      const moduleItems = groupedPageSwDetails[label];

                      return (
                        <React.Fragment key={index}>
                          <Accordion
                            expanded={expanded === label}
                            onChange={handleChange(label)}
                            key={index}
                          >
                            <AccordionSummary>
                              &nbsp;
                              <Typography component={"span"} variant={"body2"}>
                                {label}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography component={"span"} variant={"body2"}>
                                {moduleItems.map((list, index) => (
                                  <div
                                    className="edu-global-configuration__data--parameters"
                                    key={index}
                                  >
                                    <Label>
                                      {index + 1} &nbsp;
                                      {list.config_form_label_caption}
                                    </Label>
                                    {list.config_key_data_storage_type ===
                                    "BOOLEAN+MULTI-VARCHAR" ? (
                                      <>
                                        <div>
                                          <FormGroup>
                                            <FormControlLabel
                                              label=""
                                              labelPlacement="start"
                                              control={
                                                <AntSwitch
                                                  checked={
                                                    list.config_boolean_value
                                                  }
                                                  onClick={() => {
                                                    const newConfigValue =
                                                      !list.config_boolean_value;
                                                    handleChangeValue(
                                                      list.config_key,
                                                      "BOOL",
                                                      newConfigValue,
                                                      null
                                                    );
                                                  }}
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        </div>
                                        {list.config_boolean_value ? (
                                          <div>
                                            <div className="label-grid">
                                              <Label>Line 1</Label>
                                              <Input
                                                value={list.config_string_value}
                                                onChange={(
                                                  e: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                  handleChangeValue(
                                                    list.config_key,
                                                    "VARCHAR",
                                                    null,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="label-grid">
                                              <Label>Line 2</Label>
                                              <Input
                                                value={
                                                  list.config_string_value_2
                                                }
                                                onChange={(
                                                  e: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                  handleChangeValue(
                                                    list.config_key,
                                                    "VARCHAR2",
                                                    null,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    {list.config_key_data_storage_type ===
                                    "BOOLEAN+SELECT+VARCHAR+ACCTLDGR" ? (
                                      <>
                                        <div>
                                          <FormGroup>
                                            <FormControlLabel
                                              label=""
                                              labelPlacement="start"
                                              control={
                                                <AntSwitch
                                                  checked={
                                                    list.config_boolean_value
                                                  }
                                                  onClick={() => {
                                                    const newConfigValue =
                                                      !list.config_boolean_value;
                                                    handleChangeValue(
                                                      list.config_key,
                                                      "BOOL",
                                                      newConfigValue,
                                                      null
                                                    );
                                                  }}
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        </div>
                                        {list.config_boolean_value ? (
                                          <div>
                                            <div className="row">
                                              <Label>Key: </Label>
                                              <Input
                                                value={
                                                  list.config_string_value_2
                                                }
                                                onChange={(
                                                  e: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                  handleChangeValue(
                                                    list.config_key,
                                                    "VARCHAR2",
                                                    null,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div>
                                              <Label>
                                                Default Bank for Student online
                                                transaction:{" "}
                                              </Label>
                                              {/* <Select
                                       value={list.config_integer_value}
                                       onChange={(
                                         e: React.ChangeEvent<HTMLInputElement>
                                       ) => {
                                         handleChangeValue(
                                           list.config_key,
                                           "INT",
                                           null,
                                           e.target.value
                                         );
                                       }}
                                     >
                                       <option>Select</option>
                                       {responseType.map(
                                         ({ label, value }) => (
                                           <React.Fragment key={value}>
                                             <option value={value}>
                                               {label}
                                             </option>
                                           </React.Fragment>
                                         )
                                       )}
                                     </Select> */}
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    {list.config_boolean_value &&
                                    list.config_depends_on_primary_list_key &&
                                    list.config_key_data_storage_type ===
                                      "BOOLEAN+SELECT+VARCHAR" ? (
                                      <>
                                        <div>
                                          <FormGroup>
                                            <FormControlLabel
                                              label=""
                                              labelPlacement="start"
                                              control={
                                                <AntSwitch
                                                  checked={
                                                    list.config_boolean_value
                                                  }
                                                  onClick={() => {
                                                    const newConfigValue =
                                                      !list.config_boolean_value;
                                                    handleChangeValue(
                                                      list.config_key,
                                                      "BOOL",
                                                      newConfigValue,
                                                      null
                                                    );
                                                  }}
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        </div>
                                        <div>
                                          <div>
                                            <Input
                                              value={list.config_string_value_2}
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) =>
                                                handleChangeValue(
                                                  list.config_key,
                                                  "VARCHAR2",
                                                  null,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}

                                    {list.config_key_data_storage_type ===
                                    "BOOLEAN" ? (
                                      <FormGroup>
                                        <FormControlLabel
                                          label=""
                                          labelPlacement="start"
                                          control={
                                            <AntSwitch
                                              checked={
                                                list.config_boolean_value
                                              }
                                              onClick={() => {
                                                const newConfigValue =
                                                  !list.config_boolean_value;
                                                handleChangeValue(
                                                  list.config_key,
                                                  "BOOL",
                                                  newConfigValue,
                                                  null
                                                );
                                              }}
                                            />
                                          }
                                        />
                                      </FormGroup>
                                    ) : null}
                                    {list.config_key_data_storage_type ===
                                    "BOOLEAN+VARCHAR" ? (
                                      <FormGroup>
                                        <FormControlLabel
                                          label=""
                                          labelPlacement="start"
                                          control={
                                            <AntSwitch
                                              checked={
                                                list.config_boolean_value
                                              }
                                              onClick={() => {
                                                const newConfigValue =
                                                  !list.config_boolean_value;
                                                handleChangeValue(
                                                  list.config_key,
                                                  "BOOL",
                                                  newConfigValue,
                                                  null
                                                );
                                              }}
                                            />
                                          }
                                        />
                                        {list.config_boolean_value && (
                                          <Input
                                            placeholder=""
                                            value={list.config_string_value}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              handleChangeValue(
                                                list.config_key,
                                                "VARCHAR",
                                                null,
                                                e.target.value
                                              )
                                            }
                                          />
                                        )}
                                      </FormGroup>
                                    ) : null}
                                    {list.config_key_data_storage_type ===
                                    "BOOLEAN+SELECT" ? (
                                      <FormGroup>
                                        <FormControlLabel
                                          label=""
                                          labelPlacement="start"
                                          control={
                                            <AntSwitch
                                              checked={
                                                list.config_boolean_value
                                              }
                                              onClick={() => {
                                                const newConfigValue =
                                                  !list.config_boolean_value;
                                                handleChangeValue(
                                                  list.config_key,
                                                  "BOOL",
                                                  newConfigValue,
                                                  null
                                                );
                                              }}
                                            />
                                          }
                                        />
                                        {list.config_depends_on_primary_list_key &&
                                        !list.config_depends_on_secondary_list_key &&
                                        list.config_boolean_value ? (
                                          <div>
                                            <Select
                                              value={list.config_string_value}
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) => {
                                                handleChangeValue(
                                                  list.config_key,
                                                  "primary_list_key",
                                                  null,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <option>Select</option>
                                              {/* {instTypeData(
                                       list.config_primary_list_key
                                     )?.map(({ label, value }) => (
                                       <React.Fragment key={value}>
                                         <option value={value}>
                                           {label}
                                         </option>
                                       </React.Fragment>
                                     ))} */}
                                            </Select>
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    ) : null}
                                    {list.config_depends_on_primary_list_key ===
                                      false &&
                                    list.config_key_data_storage_type ===
                                      "VARCHAR" ? (
                                      <Input
                                        value={list.config_string_value}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                          handleChangeValue(
                                            list.config_key,
                                            "VARCHAR",
                                            null,
                                            e.target.value
                                          )
                                        }
                                      />
                                    ) : null}
                                    {list.config_key_data_storage_type ===
                                    "INT" ? (
                                      <div className="label-grid">
                                        <Input
                                          type="number"
                                          value={list.config_integer_value}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleChangeValue(
                                              list.config_key,
                                              "INT",
                                              null,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                                <Button
                                  mode="save"
                                  onClick={handleUpdateSwConfig}
                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </React.Fragment>
                      );
                    }
                  )}
                  <Accordion
                    expanded={expanded === "recalculate"}
                    onChange={handleChange("recalculate")}
                  >
                    <AccordionSummary>
                      &nbsp;
                      <Typography component={"span"} variant={"body2"}>
                        RECALCULATE
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component={"span"} variant={"body2"}>
                        <div>
                          <Label>Recalculate Summary counts</Label>
                          <Button onClick={handleRecalculate}>
                            Recalculate
                          </Button>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "timezone"}
                    onChange={handleChange("timezone")}
                  >
                    <AccordionSummary>
                      &nbsp;
                      <Typography component={"span"} variant={"body2"}>
                        INSTITUTION TIME ZONE
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component={"span"} variant={"body2"}>
                        <div className="label-grid">
                          <Label>Preset Time Zone</Label>
                          <Input
                            value={
                              InstDetails.data && InstDetails.data.nodes.length
                                ? InstDetails.data.nodes[0].inst_time_zone
                                : ""
                            }
                            disabled
                          />
                        </div>
                        <div className="label-grid">
                          <Label>Time Zone</Label>
                          <Autocomplete
                            classes={classes}
                            options={timeZoneOptions}
                            value={selectedTimeZone}
                            isOptionEqualToValue={(option) => {
                              return (
                                option === selectedTimeZone ||
                                selectedTimeZone === ""
                              );
                            }}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setSelectedTimeZone(newValue);
                              } else {
                                setSelectedTimeZone("");
                              }
                            }}
                            ListboxProps={{
                              onScroll: handleScroll,
                            }}
                            // openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                onChange={(e) => {
                                  setSearchTimeZone(e.target.value);
                                }}
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                        </div>

                        <br />

                        <div className="label-grid">
                          <Label>Time to send Birthday Wishes (24hrs) :</Label>
                          <Time
                            hrs={hrs}
                            mins={mins}
                            onChangeHrs={setHrs}
                            onChangeMins={setMins}
                          />
                        </div>
                        <Button mode="save" onClick={handleTimeZone} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <MessageModal
              modalFlag={message.flag!}
              value={message.message!}
              handleClose={handleClose}
              operation={message.operation!}
            />
            <LoadingModal
              flag={updationLoading || loading || recalculateLoading}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default InstConfiguration;
