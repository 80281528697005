import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SectionAllocationModalStyles } from "../../../../styles/ModalStyles";

import {
  Autocomplete,
  Checkbox,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Modal from "react-modal";

import Close from "../../../../../images/Close.svg";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import { responseType } from "../../../../../utils/Types";
import { msgType } from "../../../../../utils/FormTypes";
import { AppContext } from "../../../../../context/context";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../../utils/constants";
import {
  Direction,
  InstitutionDetails,
  Operation,
  SortBy,
  StudentReportType,
  TableHeaders,
  studentQueryTypes,
} from "../../../../../utils/Enum.types";
import { Label } from "../../../../../stories/Label/Label";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../../utils/UtilFunctions";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../../customhooks/useStudentByNodeApi";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { GetStudents } from "../../../../../queries/students/list";
import { Title } from "../../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import useToken from "../../../../../customhooks/useToken";
import useDropdownData from "../../../../../customhooks/useDropDown";
import { Keys } from "../../../../../utils/Enum.keys";
import useNotAlloactedIdFromInst from "../../../../../customhooks/useNotAlloactedIdFromInst";
import { sectionAllocationStyles } from "../../../../styles/DrawerStyles";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import StudentTotalCount from "../Components/StudentsTotalCount";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { UpdateStdSectionByIds } from "../../../../../queries/institution/sections/mutations/update";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const Allocate = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {USE_SECTION_KEY &&
            sectionDropDown.length > 0 &&
            branchSelected?.value! && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  //useStates
  const { instId } = useParams();
  const { token } = useToken();
  const drawerClasses = sectionAllocationStyles();
  const [allocateSection, setAllocateSection] = useState(false);
  const { state } = useContext(AppContext);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [rightSectionSelected, setRightSectionSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [allocatedHasNextPage, setAllocatedHasNextPage] =
    useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [allocateEndCursor, setAllocateEndCursor] = useState<string | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [allocatedStudentsList, setAllocatedStudentsList] = useState<
    StudentEdges[]
  >([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;

  const { user_details } = useLoggedInUserDetails();
  const [UpdateStdSection, { loading: updationLoading }] = useMutation(
    UpdateStdSectionByIds,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const { NotAllocatedDeptId } = useNotAlloactedIdFromInst();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  //left table
  const { StudentsData: notAllocatedStudents } = useStudentsbyNewApi(
    USE_DEPARTMENT_KEY
      ? departmentSelected
        ? departmentSelected.value
        : 0
      : NotAllocatedDeptId,
    masterIds.branchId,
    masterIds.classId,
    masterIds.semesterId,
    0,
    searchData,
    ROWS_PER_PAGE,
    StudentReportType.SECTION_ALLOCATION,
    0,
    []
  );

  // Right table
  const { StudentsData: allocatedStudents } = useStudentsbyNewApi(
    0,
    0,
    0,
    0,
    rightSectionSelected ? rightSectionSelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentReportType.ELIGIBLE,
    0,
    []
  );

  //pagination for modalview
  const righttotalCount = allocatedStudents.data?.GetStudents?.totalCount;

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAllocateSection(!allocateSection);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    departmentInputRef?.focus();
  };

  const HandleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setRightSectionSelected(null);
    setSearchData("");
    setStudents([]);
    departmentInputRef?.focus();
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };

  const handleClick = (
    data: StudentEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };

  const handleAllocationOfStudentsToSection = () => {
    UpdateStdSection({
      variables: {
        token,
        user_details,
        student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        section_id: rightSectionSelected?.value,
        inst_id: instId,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [semesterSelected ? semesterSelected.value : 0],
              std_query_type:
                studentQueryTypes.SECTION_NOT_ALLOC_BY_SEMESTER_ID,
            },
          },
        },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [instId],
              std_query_type: studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Students Moved to Section Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        HandleClear();
      }
    });
  };

  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        break;
    }
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !notAllocatedStudents.loading) {
        notAllocatedStudents.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount: notAllocatedStudents.data?.GetStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const allocateHandleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight + 2 >= getModifiedScrollHeight(scrollHeight)) {
      if (allocatedHasNextPage && !allocatedStudents.loading) {
        allocatedStudents.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: allocateEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setAllocateEndCursor(pageInfo.endCursor);
            setAllocatedHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...allocatedStudentsList!, ...newEdges],
                pageInfo,
                totalCount: allocatedStudents.data?.GetStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (notAllocatedStudents.data && !notAllocatedStudents.loading && token) {
      const newData = notAllocatedStudents.data.GetStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false, // set default value of isChecked to true
          },
        })
      );

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(notAllocatedStudents.data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [notAllocatedStudents.data, notAllocatedStudents.loading, token]);

  useEffect(() => {
    if (allocatedStudents.data && !allocatedStudents.loading && token) {
      const newData = allocatedStudents.data.GetStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: edge.node.isChecked ? true : false, // set default value of isChecked to true
        },
      }));

      if (allocateEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = allocatedStudentsList.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setAllocatedStudentsList(updatedNewData);
      } else {
        setAllocatedStudentsList(newData);
      }
      setAllocateEndCursor(
        allocatedStudents.data.GetStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [allocatedStudents.data, allocatedStudents.loading, token]);

  return (
    <>
      <form className="row g-0 section-allocation__options">
        <div className="col-1 studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              ref={departmentRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue);
                } else {
                  setDepartmentSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.DEPARTMENT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={departmentLabel}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={branchLabel}
                  id="outlined Branches"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={classLabel}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && semesterSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue);
                } else {
                  setSemesterSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.SEMESTER);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={semesterLabel}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
      </form>
      <div className="section-allocation__tableblock">
        {!students?.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <>
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    students?.filter((data) => data.node.isChecked).length
                  }
                  onSelectAllClick={(event) => handleSelectAllClick(event)}
                  rowCount={students?.length}
                />
                <TableBody>
                  {students.map((response, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={students[index]?.node.isChecked}
                        tabIndex={-1}
                        key={response.node.id}
                        selected={students[index]?.node.isChecked}
                      >
                        {USE_SECTION_KEY &&
                          sectionDropDown.length > 0 &&
                          branchSelected?.value! && (
                            <TableCell padding="checkbox" align="center">
                              <Checkbox
                                checked={
                                  students[index]?.node.isChecked ?? false
                                }
                                onClick={(event) =>
                                  handleClick(response, event)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )}
                        <TableCell
                          id="td-center"
                          className="section-allocation__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="section-allocation__table--admno">
                          {response.node.std_adm_no}
                        </TableCell>

                        <TableCell id={labelId}>
                          {response.node.std_name}
                        </TableCell>
                        {USE_BRANCH_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {response.node.branch.branch_desc}
                          </TableCell>
                        ) : null}
                        {USE_CLASS_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {response.node.class.class_desc}
                          </TableCell>
                        ) : null}
                        {USE_SECTION_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {response.node.section.section_desc}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button
            mode="move-to"
            onClick={() => setAllocateSection(!allocateSection)}
            disabled={
              !(students?.filter((data) => data.node.isChecked).length > 0)
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col-2 section-allocation__total">
          <StudentTotalCount
            totalCount={notAllocatedStudents.data?.GetStudents?.totalCount!}
          />
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={allocateSection}
        onClose={() => setAllocateSection(!allocateSection)}
      >
        <div className="section-allocation__move-to">
          <Title>Allocate Section</Title>
          <div className="row g-0 section-allocation__move-to--select">
            {USE_SECTION_KEY ? (
              <div className="col-4 section-allocation__select ">
                <Autocomplete
                  classes={classes}
                  options={sectionDropDown!}
                  value={rightSectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setRightSectionSelected(newValue);
                    } else {
                      setRightSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      InputLabelProps={{ shrink: true }}
                      label={`Move To ${sectionLabel}`}
                      required={rightSectionSelected?.value ? false : true}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div
            className="section-allocation__move-to--count"
            onClick={() =>
              rightSectionSelected && setViewStudentModal(!viewStudentModal)
            }
          >
            <b>{rightSectionSelected && righttotalCount}</b>
            <Label>Students :</Label>
            <Label>{sectionLabel}</Label>
          </div>
          <div className="section-allocation__move-to--tableblock">
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />

                <TableBody>
                  {students
                    .filter((student) => student.node.isChecked)
                    .map((response, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>

                          <TableCell>{response.node.std_name}</TableCell>
                          {USE_BRANCH_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.branch.branch_desc}
                            </TableCell>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.class.class_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {
                                sectionDropDown?.find(
                                  ({ value }) =>
                                    Number(value) ===
                                    rightSectionSelected?.value
                                )?.label
                              }
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            mode="save"
            onClick={handleAllocationOfStudentsToSection}
            disabled={rightSectionSelected?.value ? false : true}
          />
          <Button
            mode="cancel"
            onClick={() => {
              HandleClear();
              setAllocateSection(!allocateSection);
            }}
          />
          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          <LoadingModal flag={updationLoading} />
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={viewStudentModal}
          ariaHideApp={false}
          style={SectionAllocationModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Title> List of Students In {sectionLabel}</Title>
              <div className="section-allocation__allocated">
                <TableContainer
                  className="section-allocation__table"
                  onScroll={allocateHandleScroll}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className="section-allocation__table--th-slno">
                          {TableHeaders.SLNO}
                        </TableCell>
                        <TableCell className="section-allocation__table--th-admno">
                          {TableHeaders.ADMISSION_NUMBER}
                        </TableCell>
                        <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                        {USE_DEPARTMENT_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {departmentLabel}
                          </TableCell>
                        ) : null}
                        {USE_BRANCH_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {branchLabel}
                          </TableCell>
                        ) : null}

                        {USE_CLASS_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {classLabel}
                          </TableCell>
                        ) : null}

                        {USE_SEMESTER_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {semesterLabel}
                          </TableCell>
                        ) : null}
                        {USE_SECTION_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {sectionLabel}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rightSectionSelected &&
                        allocatedStudentsList?.length > 0 &&
                        allocatedStudentsList?.map(
                          (response, index: number) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow>
                                <TableCell
                                  id="td-center"
                                  className="section-allocation__table--slno"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell className="section-allocation__table--admno">
                                  {response.node.std_adm_no}
                                </TableCell>

                                <TableCell id={labelId}>
                                  {response.node.std_name}
                                </TableCell>
                                {USE_DEPARTMENT_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.dept.dept_desc}
                                  </TableCell>
                                ) : null}
                                {USE_BRANCH_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.branch.branch_desc}
                                  </TableCell>
                                ) : null}

                                {USE_CLASS_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.class.class_desc}
                                  </TableCell>
                                ) : null}

                                {USE_SEMESTER_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.semester.sem_desc}
                                  </TableCell>
                                ) : null}
                                {USE_SECTION_KEY ? (
                                  <TableCell className="section-allocation__table--desc">
                                    {response.node.section.section_desc}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                    {/* <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={PAGINATION_ARRAY}
                          count={righttotalCount ? righttotalCount : 0}
                          rowsPerPage={modalRowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeModalRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          labelDisplayedRows={defaultLabelDisplayedRows}
                          labelRowsPerPage={TABLE_DATA_PER_PAGE}
                        />
                      </TableRow>
                    </TableFooter> */}
                  </Table>
                </TableContainer>
              </div>
              <div className="row g-0">
                <div className="col">
                  <Button
                    mode="cancel"
                    onClick={() => setViewStudentModal(!viewStudentModal)}
                  />
                </div>
                <div className="col section-allocation--total-count">
                  <StudentTotalCount
                    totalCount={allocatedStudents.data?.GetStudents.totalCount!}
                  />
                </div>
              </div>
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => setViewStudentModal(!viewStudentModal)}
              />
            </div>
          </div>
        </Modal>
      </Drawer>
    </>
  );
};

export default Allocate;
