import React, { useContext, useEffect, useRef, useState } from "react";
import { Chip, Divider, Drawer, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import Delete from "../../../../images/Delete.svg";
import Edit from "../../../../images/EditProfile.svg";
import Add from "../../../../images/Add.svg";
import Avatar from "../../../../images/Avatar.svg";
import More from "../../../../images/DownArrow.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import CreateAnnouncement from "./Create";
import { useStyles } from "../../../styles/TooltipStyles";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { attendanceOverViewStyles } from "../../../../styles/DrawerStyles";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import {
  GetChannelMessagesData,
  GetChannelMessagesVars,
  GetMessageData,
  GetMessageDataVariables,
  MessageEdge,
} from "../Feeds/Types";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Types";
import {
  GetChannelMessages,
  GetMessageDataById,
} from "../../../../queries/chat/feeds/list";
import { DeleteChannelMessage } from "../../../../queries/chat/mutations";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  licenseTypes,
} from "../../../../utils/constants";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../Modals/LoadingModal";
import Navbar from "../../../Layouts/Navbar";
import { Title } from "../../../../stories/Title/Title";
import Sidebar from "../../../Layouts/Sidebar";
import useChannels from "../CustomHooks/useChannels";
import DeleteModal from "../../../Modals/DeleteModal";
import useEmpDetailsById from "../../../../customhooks/useEmpDetailsById";
import MessageModal from "../../../../utils/MessageModal";

const ViewDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { instId } = useParams();
  const tooltipclasses = useStyles();
  const { user_details } = useLoggedInUserDetails();
  const drawerClasses = attendanceOverViewStyles();
  const [createFeed, setCreateFeed] = useState(false);
  const messageListRef = useRef<HTMLUListElement>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [messageId, setMessageId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const { employeeFormData } = useEmpDetailsById(true);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [userList, setUserList] = useState<Map<number, string>>(new Map());

  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(true);
  const { data } = useChannels(ChannelQueryType.ANNOUNCEMENTS);
  const [DeleteMessage, { loading: DeleteLoading }] = useMutation(
    DeleteChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;

  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesData, GetChannelMessagesVars>(
      GetChannelMessages
    );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;

    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: ROWS_PER_PAGE,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData?.GetChannelMessages.totalCount!,
            },
          };
        },
      });
    }
  };

  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const HandleDelete = (id: Number) => {
    DeleteMessage({
      variables: {
        token,
        msg_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelMessages,
          variables: {
            token,
            inst_id: instId ? instId : state.InstId.toString(),
            input: {
              query_type: MsgChannelType.ANNOUNCEMENTS,
              channel_id: channelId,
              start_date: toIsoDate(DEFAULT_TIME),
              end_date: toIsoDate(DEFAULT_TIME),
            },
            direction: Direction.ASC,
            last: 50,
            after: null,
            sortBy: SortBy.CREATED_AT,
            messageContent: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
    setDeleteModal(!deleteModal);
  };
  useEffect(() => {
    if (channelId && token) {
      setAllMessages([]);

      GetMessages({
        variables: {
          token,
          inst_id: instId ? instId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.ANNOUNCEMENTS,
            channel_id: channelId,
            start_date: toIsoDate(DEFAULT_TIME),
            end_date: toIsoDate(DEFAULT_TIME),
            entry_id: 0,
          },
          direction: Direction.ASC,
          last: 50,
          after: null,
          sortBy: SortBy.CREATED_AT,
          messageContent: "",
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [channelId, token, MessagesData, GetMessages, data]);

  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res) {
                  if (res.first_name)
                    return [
                      res.id,
                      `${
                        res.first_name +
                        " " +
                        res.middle_name +
                        " " +
                        res.last_name
                      }`,
                    ];
                  else if (res.emp_name) return [res.id, `${res.emp_name}`];
                  else return [res.id, `${res.std_name}`];
                } else return [0, ""];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);
  useEffect(() => {
    if (MessagesData && !loading) {
      const newMessages = MessagesData.GetChannelMessages.edges;

      if (startCursor) {
        const updatedNewMessages = newMessages.map((newMessage) => {
          const filteredMessage = allMessages.find(
            (message) => message.node.id === newMessage.node.id
          );
          if (filteredMessage) {
            return {
              ...newMessage,
              node: {
                ...newMessage.node,
              },
            };
          }
          return newMessage;
        });
        setAllMessages(updatedNewMessages);
      } else {
        setAllMessages(newMessages);
      }

      const pageInfo = MessagesData.GetChannelMessages.pageInfo;
      setStartCursor(pageInfo.startCursor);
      setHasPreviousPage(pageInfo.hasPreviousPage);
    } // eslint-disable-next-line
  }, [MessagesData, loading, startCursor]);
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="feeds">
        <Sidebar />
        <div className="feeds__frame row g-0 justify-content-center">
          <Title>Announcements</Title>
          <div className="feeds__data col-6">
            <div className="feeds__data--list">
              <div
                className="feeds__data--list--content"
                onScroll={handleScroll}
              >
                <ul ref={messageListRef}>
                  {Object.entries(byDate)?.map(([date, messages]) => (
                    <React.Fragment key={date}>
                      <Divider>
                        <Chip label={date} />
                      </Divider>
                      {messages.map((message, index) => {
                        const parsedContent = parseMessageContent(
                          message.node.msg_content
                        );
                        const parsedContentHeader = parseMessageContent(
                          message.node.msg_header
                        );

                        const id = state.claims?.EMPLOYEE
                          ? state.empLoginId
                          : state.studentId;
                        const isSentUser = id === message.node.sent_user_id;

                        return (
                          <li key={index} className="feeds__messages--list">
                            {!isSentUser && (
                              <img
                                src={Avatar}
                                alt="/"
                                className="feeds__messages--list--image"
                              />
                            )}
                            <div className="feeds__messages--list--sender">
                              <div className="feeds__messages--list--flex">
                                <b className="feeds__messages--list--name">
                                  {!isSentUser && (
                                    <>
                                      {userList.get(message.node.sent_user_id)}
                                    </>
                                  )}
                                </b>
                                {employeeFormData.can_send_announcement ? (
                                  <Tooltip
                                    onClick={() =>
                                      setEnableToolTipModal(!enableToolTipModal)
                                    }
                                    classes={{
                                      tooltip: tooltipclasses.studentOptions,
                                    }}
                                    placement="right"
                                    title={
                                      enableToolTipModal && (
                                        <>
                                          <ul className="feeds__messages--list--tooltip--ul">
                                            <li
                                              onClick={() => {
                                                setOperation(Operation.UPDATE);
                                                setMessageId(message.node.id);
                                                setCreateFeed(!createFeed);
                                              }}
                                              className="studentlist__table--more--fee"
                                            >
                                              <img src={Edit} alt="/" />
                                              <span> Edit Message</span>
                                            </li>
                                            <li
                                              onClick={() => {
                                                setDeleteModal(!deleteModal);

                                                setDeleteId(message.node.id);
                                              }}
                                              className="studentlist__table--more--fee"
                                            >
                                              <img src={Delete} alt="/" />
                                              <span> Delete Message</span>
                                            </li>
                                          </ul>
                                        </>
                                      )
                                    }
                                  >
                                    <span className="feeds__messages--list--time">
                                      {dayjs(message.node.created_at).format(
                                        "LT"
                                      )}
                                      <img src={More} alt="/" />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <span className="feeds__messages--list--time">
                                    {dayjs(message.node.created_at).format(
                                      "LT"
                                    )}
                                  </span>
                                )}
                              </div>

                              {message.node.msg_header && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: parsedContentHeader,
                                  }}
                                />
                              )}
                              {message.node.msg_content && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: parsedContent,
                                  }}
                                />
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              onClick={() => {
                setOperation(Operation.CREATE);
                setCreateFeed(!createFeed);
              }}
              disabled={employeeFormData.can_send_feeds === false}
            >
              <img src={Add} alt="" />
              Create New Announcement
            </Button>
          </div>
        </div>
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={createFeed}
        onClose={() => setCreateFeed(!createFeed)}
      >
        <CreateAnnouncement
          setModalFlag={setCreateFeed}
          operation={operation}
          messageId={messageId}
        />
      </Drawer>
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <LoadingModal flag={DeleteLoading} />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ViewDetails;
