import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import * as Excel from "exceljs";

import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import TotalStudents from "../../../../images/TotalMale.svg";
import ReadyToImport from "../../../../images/Present.svg";
import ContainsError from "../../../../images/Absent.svg";
import Info from "../../../../images/Information.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import FileAttach from "../../../../images/BrowseFiles.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Validate from "../../../../images/Generate.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { Title } from "../../../../stories/Title/Title";
import {
  toIsoDate,
  convertToDateObject_MMDDYYYYFormat,
  getHeaderRowStyle,
  toStandardDate,
  handleClear,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { _genderRegex } from "../../../../utils/validationRules";
import { useStyles } from "../../../../styles/TooltipStyles";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import {
  ExcelAlignment,
  ExcelPageHeader,
  GetSwConfigVariablesVars,
  InstitutionConfigurationTypes,
  Operation,
  SwConfigQueryType,
} from "../../../../utils/Enum.types";

import { AppContext } from "../../../../context/context";

import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_ALIGNMENT_LEFT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
  emptyMessageType,
  licenseTypes,
} from "../../../../utils/constants";
import useServerDateandTime from "../../../../customhooks/useServerDateandTime";
import { ImportStudentDataTableStyles } from "../../../../styles/StickyTableStyles";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useSwConfigData from "../../../../customhooks/useSwConfighData";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import useMstInstTree from "../../../../customhooks/useMstInstTree";
import useInstLabels from "../../../../customhooks/useInstLabels";
import { GetSwConfigVariablesData } from "../../../../utils/Query.Types";
import { GetSwConfigVariables } from "../../../../queries/institution/configuration/query/SoftwareConfig";
import Navbar from "../../../Layouts/Navbar";
import Close from "../../../../images/Close.svg";

import { GetInstMasterData } from "../../../../queries/institution/queries/byId";
import { ValidateStudentDataForImport } from "../../../../queries/xls";
import { ImportStudentDataFromXls } from "../../../../queries/xls/mutation";
import useInstDetails from "../../../../customhooks/useInstDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GetInstMasterDataDetails,
  GetInstMasterDataVars,
} from "../../../../customhooks/useInstMasterDataByInstId";
import { msgType } from "../../../../utils/Types";
import MessageModal from "../../../../utils/MessageModal";
import Sidebar from "../../../Layouts/Sidebar";
import ReactModal from "react-modal";
import ExcelLoading from "../../Chat/ExcelLoading";
import { LoadingStyles } from "../../../styles/ModalStyles";
interface ValidateStudentDataForImportDataInput {
  std_name: string;
  std_status: string;
  std_sex: string;
  std_adm_no: string;
  std_dob: string;
  std_email: string;
  std_mobile: string;
  parent_name: string;
  dept_name: string;
  branch_name: string;
  class_name: string;
  semester_name: string;
  section_name: string;
}

interface ValidateStudentDataForImportVars {
  token: string;
  inst_id: string;
  input: ValidateStudentDataForImportDataInput;
}
interface ValidateStudentDataForImportData {
  ValidateStudentDataForImport: boolean;
}
interface dr {
  Class: string;
  Department: string;
  Branch: string;
  Semester: string;
  Section: string;
}

const StudentBasicDataImport = () => {
  const { token } = useToken();
  const { instId } = useParams();
  const { serverDate } = useServerDateandTime();

  const classes = ImportStudentDataTableStyles();
  const tooltipClasses = useStyles();
  const { InstLogo } = useInstLogoDetails();

  const [expanded, setExpanded] = useState(false);
  const [student_schema, setStudentSchema] = useState({});
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [records, setRecords] = useState<any[]>([]);
  const [GetMasterDetails, { data }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: instId!,
    },
  });
  const [successCount, setSuccessCount] = useState(0);

  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const { data: treeData } = useMstInstTree(0, 0, 0, 0, 0);
  const [ValidateEachRow] = useLazyQuery<
    ValidateStudentDataForImportData,
    ValidateStudentDataForImportVars
  >(ValidateStudentDataForImport);
  const [AddStudent, { loading }] = useMutation(ImportStudentDataFromXls);
  const { InstDetails } = useInstDetails();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_STUDENT_LOGIN
  );
  const [importModal, setImportModal] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const classDetails = data?.GetInstMasterData.class_details || [];
  const deptDetails = data?.GetInstMasterData.dept_details || [];
  const branchDetails = data?.GetInstMasterData.branch_details || [];
  const sectionDetails = data?.GetInstMasterData.section_details || [];
  const semesterDetails = data?.GetInstMasterData.semester_details || [];
  const maxLength = Math.max(
    classDetails.length,
    deptDetails.length,
    branchDetails.length,
    sectionDetails.length,
    semesterDetails.length
  );
  let arrayTreeMapedData: dr[] = [];
  for (let index = 0; index < maxLength; index++) {
    const classe = classDetails[index]?.class_desc;
    const dept = deptDetails[index]?.dept_desc;
    const branche = branchDetails[index]?.branch_desc;
    const sectione = sectionDetails[index]?.section_desc;
    const semestere = semesterDetails[index]?.sem_desc;

    arrayTreeMapedData.push({
      Class: classe || "",
      Department: dept || "",
      Branch: branche || "",
      Semester: semestere || "",
      Section: sectione || "",
    });
  }
  const [GetConfigdata, { data: stsData }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: instId ? instId : state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });
  const stsNoFlag = stsData?.GetSwConfigVariables[0].config_string_value;

  const instConfig = [
    {
      Headers: departmentLabel,
      bool: USE_DEPARTMENT_KEY,
      key: departmentLabel,
      width: 25,
    },
    {
      Headers: branchLabel,
      bool: USE_BRANCH_KEY,
      key: branchLabel,
      width: 25,
    },

    {
      Headers: classLabel,
      bool: USE_CLASS_KEY,
      key: classLabel,
      width: 25,
    },
    {
      Headers: semesterLabel,
      bool: USE_SEMESTER_KEY,
      key: semesterLabel,
      width: 25,
    },
    {
      Headers: sectionLabel,
      bool: USE_SECTION_KEY,
      key: sectionLabel,
      width: 25,
    },
  ];
  const studentData = [
    {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: true,
      use_in_sheet: true,
    },
    stsNoFlag === "SCH"
      ? {
          Headers: "STSno",
          key: "std_sts_no",
          width: 50,
          required: false,
          use_in_sheet: true,
        }
      : {},
    {
      Headers: "StudentName",
      key: "student_name",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Email",
      key: "email",
      width: 50,
      required: configData.data?.GetSwConfigVariables[0].config_boolean_value,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "ContactNumber",
      key: "contactnumber",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "ParentName",
      key: "parentname",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "DateOfBirth",
      key: "dob",
      width: 50,
      required: true,
      type: String,
      message: "requires",
      use_in_sheet: true,
    },

    {
      Headers: "Gender",
      key: "sex",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: departmentLabel,
      width: 50,
      required: USE_DEPARTMENT_KEY,
      type: String,
      key: departmentLabel,
      use_in_sheet: USE_DEPARTMENT_KEY,
    },
    {
      Headers: branchLabel,
      width: 50,
      required: USE_BRANCH_KEY,
      type: String,
      key: branchLabel,
      use_in_sheet: USE_BRANCH_KEY,
    },
    {
      Headers: classLabel,
      width: 50,
      required: USE_CLASS_KEY,
      type: String,
      key: classLabel,
      use_in_sheet: USE_CLASS_KEY,
    },
    {
      Headers: semesterLabel,
      width: 50,
      required: USE_SEMESTER_KEY,
      type: String,
      key: semesterLabel,
      use_in_sheet: USE_SEMESTER_KEY,
    },
    {
      Headers: sectionLabel,
      width: 50,
      required: USE_SECTION_KEY,
      type: String,
      key: sectionLabel,
      use_in_sheet: USE_SECTION_KEY,
    },
  ];
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  useEffect(() => {
    setStudentSchema({
      ...(stsNoFlag === "SCH"
        ? {
            STSno: {
              name: "std_sts_no",
            },
          }
        : {}),
      AdmissionNumber: {
        name: "std_adm_no",
        required: true,
      },
      StudentName: {
        name: "student_name",
        required: true,
      },
      Email: {
        name: "email",
      },
      ContactNumber: {
        name: "contactnumber",
      },
      ParentName: {
        name: "parentname",
        required: true,
      },
      MotherName: {
        name: "mothername",
      },
      DateOfBirth: {
        name: "dob",
        required: true,
      },
      Gender: {
        name: "sex",
        required: true,
      },
      [departmentLabel]: {
        name: departmentLabel,
        required: USE_DEPARTMENT_KEY,
      },
      [branchLabel]: {
        name: branchLabel,
        required: USE_BRANCH_KEY,
      },

      [classLabel]: {
        name: classLabel,
        required: USE_CLASS_KEY,
      },
      [semesterLabel]: {
        name: semesterLabel,
        required: USE_SEMESTER_KEY,
      },
      [sectionLabel]: { name: sectionLabel, required: USE_SECTION_KEY },
    });
  }, [
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    stsNoFlag,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);

  const schemaKeys = Object.keys(student_schema);

  const filteredKeys = schemaKeys.filter((key) => {
    // @ts-ignore
    const fieldSchema = student_schema[key];
    if (fieldSchema.required || fieldSchema.required === undefined) {
      const hasData = records.some(
        (record) => record[key] !== undefined && record[key] !== ""
      );
      return hasData;
    }
    return false;
  });

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;

  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      adm_no: f.AdmissionNumber,
      student_name: f.StudentName,
      email: f.Email,
      contactnumber: f.ContactNumber,
      parentname: f.ParentName,
      dob: f.DateOfBirth,
      sex: f.Gender,
      ...(USE_DEPARTMENT_KEY ? { [departmentLabel]: f.departmentLabel } : {}),
      ...(USE_BRANCH_KEY ? { [branchLabel]: f.branchLabel } : {}),
      ...(USE_CLASS_KEY ? { [classLabel]: f.classLabel } : {}),
      ...(USE_SEMESTER_KEY ? { [semesterLabel]: f.semesterLabel } : {}),
      ...(USE_SECTION_KEY ? { [sectionLabel]: f.sectionLabel } : {}),
    }));
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");

    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(InstLogo.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const downloadExcelContainsError = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });
    worksheet1.getColumn(1).width = 15;
    worksheet1.getColumn(2).width = 15;
    worksheet1.getColumn(3).width = 15;
    worksheet1.getColumn(4).width = 15;
    worksheet1.getColumn(5).width = 15;
    worksheet1.getColumn(6).width = 15;
    worksheet1.getColumn(7).width = 15;
    worksheet1.getColumn(8).width = 15;
    worksheet1.getColumn(9).width = 15;
    worksheet1.getColumn(10).width = 15;
    worksheet1.getColumn(11).width = 15;
    worksheet1.getColumn(12).width = 15;
    worksheet1.getColumn(13).width = 15;
    worksheet1.getColumn(14).width = 15;
    worksheet1.getColumn(15).width = 15;
    worksheet1.getColumn(16).width = 15;
    worksheet1.getColumn(17).width = 15;
    worksheet1.getColumn(18).width = 15;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(InstLogo.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  // mergedYear.value = state.ActiveFinYr
                  //   ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                  //   : ExcelPageHeader.YEAR;

                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;
                  let headers = instConfig
                    .filter((d) => d.bool)
                    .map((f) => f.Headers);
                  let headers2 = studentData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < headers.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = headers[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);

                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  let dataRow = 6;
                  treeData?.GetMstInstTree.forEach((level1_data) => {
                    let level1_nxt_index = 0;
                    if (
                      level1_data.level_2_details &&
                      level1_data.level_2_details.length > 0
                    ) {
                      let level2_dataRow = dataRow;
                      level1_data.level_2_details.forEach((level2_data) => {
                        let level2_nxt_index = 0;
                        if (
                          level2_data.level_3_details &&
                          level2_data.level_3_details.length > 0
                        ) {
                          let level3_dataRow = level2_dataRow;
                          level2_data.level_3_details.forEach((level3_data) => {
                            let level3_nxt_index = 0;
                            if (
                              level3_data.level_4_details &&
                              level3_data.level_4_details.length > 0
                            ) {
                              let level4_dataRow = level3_dataRow;
                              level3_data.level_4_details.forEach(
                                (level4_data) => {
                                  let level4_nxt_index = 0;
                                  if (
                                    level4_data.level_5_details &&
                                    level4_data.level_5_details.length > 0
                                  ) {
                                    level4_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level3_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level1_nxt_index +=
                                      level4_data.level_5_details.length;
                                    level2_nxt_index +=
                                      level4_data.level_5_details.length;
                                    let level5_dataRow = level4_dataRow;
                                    level4_data.level_5_details.forEach(
                                      (level5_data, index) => {
                                        const level5_cell = worksheet.getCell(
                                          `E${level5_dataRow}`
                                        );
                                        level5_cell.value =
                                          level5_data.level_5_desc;

                                        level5_cell.style = {
                                          alignment: HEADER_ALIGNMENT_CENTER,
                                        };
                                        level5_dataRow += index + 1;
                                      }
                                    );
                                  } else {
                                    level4_nxt_index = 1;
                                    level3_nxt_index += 1;
                                    level2_nxt_index += 1;
                                    level1_nxt_index += 1;
                                  }
                                  const level4_cell = worksheet.getCell(
                                    `D${level4_dataRow}`
                                  );
                                  level4_cell.value = level4_data.level_4_desc;
                                  worksheet.mergeCells(
                                    `D${level4_dataRow}:D${
                                      level4_dataRow + level4_nxt_index - 1
                                    }`
                                  );
                                  level4_cell.style = {
                                    alignment: HEADER_ALIGNMENT_CENTER,
                                  };
                                  level4_dataRow += level4_nxt_index;
                                }
                              );
                            } else {
                              level3_nxt_index = 1;
                              level2_nxt_index += 1;
                              level1_nxt_index += 1;
                            }
                            const level3_cell = worksheet.getCell(
                              `C${level3_dataRow}`
                            );
                            level3_cell.value = level3_data.level_3_desc;
                            worksheet.mergeCells(
                              `C${level3_dataRow}:C${
                                level3_dataRow + level3_nxt_index - 1
                              }`
                            );
                            level3_cell.style = {
                              alignment: HEADER_ALIGNMENT_CENTER,
                            };
                            level3_dataRow += level3_nxt_index;
                          });
                        } else {
                          level2_nxt_index = 1;
                          level1_nxt_index += 1;
                        }

                        const level2_cell = worksheet.getCell(
                          `B${level2_dataRow}`
                        );
                        level2_cell.value = level2_data.level_2_desc;
                        worksheet.mergeCells(
                          `B${level2_dataRow}:B${
                            level2_dataRow + level2_nxt_index - 1
                          }`
                        );
                        level2_cell.style = {
                          alignment: HEADER_ALIGNMENT_CENTER,
                        };
                        level2_dataRow += level2_nxt_index;
                      });
                    } else {
                      level1_nxt_index = 1;
                    }

                    const level1_cell = worksheet.getCell(`A${dataRow}`);
                    level1_cell.value = level1_data.level_1_desc;
                    worksheet.mergeCells(
                      `A${dataRow}:A${dataRow + level1_nxt_index - 1}`
                    );
                    level1_cell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };
                    dataRow += level1_nxt_index;
                  });

                  worksheet1.columns = studentData.filter(
                    (d) => d.use_in_sheet
                  );
                  errorData.forEach((f) => worksheet1.addRow(f));
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName + "error");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);

    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];

      student_schema &&
        // eslint-disable-next-line
        Object.keys(student_schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];

          let keyValue = record[key];

          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }

          if (
            key === "DateOfBirth" &&
            !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          ) {
            let errorObj = {
              column: key,
              error:
                "Invalid D.O.B, the format should be DD/MM/YYYY or DD-MM-YYYY",
            };
            errors.push(errorObj);
          }

          if (key === "Gender" && !_genderRegex.mask.test(keyValue)) {
            let errorObj = {
              column: key,
              error: "Invalid Gender, the format should be male or female",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });

    return records;
  };
  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id: number) => {
    return new Promise((resolve, reject) => {
      const filteredData = records.find((record) => record.id === id);
      if (filteredData) {
        ValidateEachRow({
          variables: {
            input: {
              std_name: filteredData.StudentName,
              parent_name: filteredData.ParentName,
              std_dob: toIsoDate(
                convertToDateObject_MMDDYYYYFormat(filteredData.DateOfBirth)
              ),
              std_email: filteredData.Email ?? "",
              std_mobile: filteredData.ContactNumber,
              dept_name: filteredData[departmentLabel] ?? "",
              branch_name: filteredData[branchLabel] ?? "",
              class_name: filteredData[classLabel] ?? "",
              semester_name: filteredData[semesterLabel] ?? "",
              section_name: filteredData[sectionLabel] ?? "",
              std_adm_no: filteredData.AdmNo ?? "",
              std_sex: filteredData.Gender ?? "",
              std_status: "",
            },
            inst_id: instId!,
            token,
          },
        }).then(({ data, error }) => {
          if (data) {
            const newData = records.map((record) => {
              if (id === record.id) {
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: !record.isValidated,
                  isValidatedAndError: false,
                };
              }
              return record;
            });
            setRecords(newData);

            resolve(data);
          }
          if (error) {
            const newData = records.map((record) => {
              if (record.id === id) {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(error?.message);
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: true,
                  isValidatedAndError: true,
                };
              }
              return record;
            });
            setRecords(newData);
            reject(error);
          }
        });
      }
    });
  };
  const handleAddStudent = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);

    const validRecords = records.filter(
      (record) => !record.isValidatedAndError
    );

    for (const record of validRecords) {
      try {
        const { data } = await AddStudent({
          variables: {
            token,
            inst_id: instId,
            user_details,
            input: {
              std_name: removeMoreSpace(record.StudentName),
              std_status: "YES",
              std_sex: removeMoreSpace(record.Gender).toUpperCase(),
              std_adm_no: record.AdmissionNumber ?? "",
              std_dob: toIsoDate(
                convertToDateObject_MMDDYYYYFormat(record.DateOfBirth)
              ),
              std_email: removeMoreSpace(record.Email),
              std_mobile: `+91${record.ContactNumber}`,
              parent_name: removeMoreSpace(record.ParentName),
              dept_name: record[departmentLabel] ?? "",
              branch_name: record[branchLabel] ?? "",
              class_name: record[classLabel] ?? "",
              semester_name: record[semesterLabel] ?? "",
              section_name: record[sectionLabel] ?? "",
            },
          },
        });

        if (data) {
          setSuccessCount((prev) => prev + 1);
        }
      } catch (err) {
        console.error(err);
        record.errors = record.errors ? record.errors : [];
        setErrorCount((prev) => prev + 1);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);

          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                std_status: "YES",
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });
  useEffect(() => {
    if (token && instId) {
      GetConfigdata();
    }
  }, [token, GetConfigdata, instId]);

  useEffect(() => {
    if (token) {
      GetMasterDetails();
    }
  }, [token, GetMasterDetails]);
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />

      <div className="import-excel-data">
        <Sidebar />
        <div className="import-excel-data__frame">
          <Title>Import Student Data</Title>
          <div className="row g-0 import-excel-data__blocks">
            <div className="col-6">
              <div className="import-excel-data__file-upload">
                <div className="import-excel-data__file-upload--file-name">
                  <span id="file-name"></span>
                </div>
                <div className="import-excel-data__file-upload--browse">
                  <label htmlFor="input">
                    <img src={FileAttach} alt="/" /> Choose Excel File
                  </label>
                  <input
                    id="input"
                    type="file"
                    name="file"
                    onChange={() => displayFileName()}
                    className="import-excel-data__file-upload--input"
                  />
                </div>
              </div>
            </div>

            <div className="col import-excel-data__blocks--cards">
              <b>
                <img src={TotalStudents} alt="/" />
                Total Students
              </b>
              <span className="import-excel-data__blocks--cards--total-students">
                {records.length}
              </span>
            </div>
            <div
              className="col import-excel-data__blocks--cards"
              onClick={() => setExpanded(!expanded)}
            >
              <b>
                <img src={ReadyToImport} alt="/" />
                Ready to Import
              </b>
              <span className="import-excel-data__blocks--cards--ready-to-import">
                {records.filter((record) => !record.isValidatedAndError)
                  .length - records.filter((record) => record?.errors).length}
              </span>
            </div>
            <div
              className="col import-excel-data__blocks--cards"
              onClick={() => setExpanded(!expanded)}
            >
              <b>
                <img src={ContainsError} alt="/" />
                Contains Error
              </b>
              <span className="import-excel-data__blocks--cards--contains-error">
                {records.filter((record) => record?.errors).length}
              </span>
            </div>
          </div>
          <div className="import-excel-data__datablock">
            {records.length > 0 && student_schema ? (
              <>
                <div
                  className="import-excel-data__datablock--title"
                  onClick={() => setExpanded(!expanded)}
                >
                  <div className="import-excel-data__datablock--records-count">
                    <span>
                      {
                        records.filter((record) => !record.isValidatedAndError)
                          .length
                      }
                    </span>
                    <b> Records Found to Import</b>
                  </div>
                  <img src={DownArrow} alt="/" />
                </div>
                {!expanded && (
                  <div className="import-excel-data__datablock--tableblock">
                    <TableContainer className={classes.table}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.stickyHeaderSl}
                              id="td-center"
                            >
                              Sl
                            </TableCell>

                            {filteredKeys?.map((key, index) => {
                              // @ts-ignore
                              return (
                                <TableCell
                                  key={index}
                                  className={
                                    key === "StudentName"
                                      ? classes.stickyHeaderName
                                      : key === "AdmissionNumber"
                                      ? classes.stickyHeaderAdmNo
                                      : classes.stickyHeader
                                  }
                                  id="td-center"
                                >
                                  {key}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {records
                            .filter((record) => !record?.errors)
                            .map((record, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.stickyColumnSl}
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>
                                {/* @ts-ignore */}
                                {/* eslint-disable-next-line */}
                                {filteredKeys.map((key, index) => {
                                  // @ts-ignore
                                  const fieldSchema = student_schema[key];

                                  if (
                                    fieldSchema.required ||
                                    fieldSchema.required === undefined
                                  )
                                    return (
                                      <TableCell
                                        key={index}
                                        className={
                                          fieldSchema?.name === "adm_no"
                                            ? classes.stickyColumnAdmno
                                            : fieldSchema?.name ===
                                              "student_name"
                                            ? classes.stickyColumnName
                                            : classes.stickyColumn
                                        }
                                      >
                                        {record[key]}
                                      </TableCell>
                                    );
                                })}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                <div
                  className="import-excel-data__datablock--title"
                  onClick={() => setExpanded(!expanded)}
                >
                  <div className="import-excel-data__datablock--errors-count">
                    <span>{records.filter((d) => d?.errors).length}</span>
                    <b>Records Which Contains error(s)</b>
                  </div>
                  <img src={DownArrow} alt="/" />
                </div>
                {expanded && (
                  <div className="import-excel-data__datablock--tableblock">
                    <TableContainer className={classes.table}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.stickyHeaderSl}
                              id="td-center"
                            >
                              Sl
                            </TableCell>
                            {/* eslint-disable-next-line */}
                            {filteredKeys.map((key, index) => {
                              // @ts-ignore
                              const fieldSchema = student_schema[key];

                              if (
                                fieldSchema.required ||
                                fieldSchema.required === undefined
                              )
                                return (
                                  <TableCell
                                    key={index}
                                    className={
                                      key === "StudentName"
                                        ? classes.stickyHeaderName
                                        : key === "AdmissionNumber"
                                        ? classes.stickyHeaderAdmNo
                                        : classes.stickyHeader
                                    }
                                    id="td-center"
                                  >
                                    {key}
                                  </TableCell>
                                );
                            })}
                            <TableCell
                              className={classes.stickyHeaderStatus}
                              id="td-center"
                            >
                              Error Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {records
                            .filter((record) => record?.errors)
                            .map((record, index) => {
                              const filteredHeaders: string[] = [];

                              return (
                                <TableRow key={index}>
                                  {/* <TableCell
                                  className={classes.stickyColumnCheckbox}
                                  id="td-center"
                                ></TableCell> */}

                                  <TableCell
                                    className={classes.stickyColumnSl}
                                    id="td-center"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  {filteredKeys.map((key, index) => {
                                    // @ts-ignore
                                    const fieldSchema = student_schema[key];
                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    ) {
                                      filteredHeaders.push(key);
                                      return (
                                        <TableCell
                                          key={index}
                                          className={
                                            fieldSchema?.name === "adm_no"
                                              ? classes.stickyColumnAdmno
                                              : fieldSchema?.name ===
                                                "student_name"
                                              ? classes.stickyColumnName
                                              : classes.stickyColumn
                                          }
                                        >
                                          {record[key]}
                                        </TableCell>
                                      );
                                    }
                                    return null;
                                  })}

                                  <TableCell
                                    className={classes.stickyColumnStatus}
                                  >
                                    <Tooltip
                                      title={
                                        <ul>
                                          {record?.errors
                                            ? record?.errors?.map(
                                                (error: any, index: number) => {
                                                  return (
                                                    <li key={index}>
                                                      {index + 1})&nbsp;
                                                      {typeof error === "string"
                                                        ? error
                                                        : "Error message = " +
                                                          error.error +
                                                          ", column = " +
                                                          error.column}
                                                    </li>
                                                  );
                                                }
                                              )
                                            : null}
                                        </ul>
                                      }
                                      arrow
                                      placement="bottom-start"
                                      classes={{
                                        tooltip:
                                          tooltipClasses.customTooltipError,
                                        arrow: tooltipClasses.customArrowError,
                                      }}
                                    >
                                      <div className="import-excel-data__datablock--tableblock--error-message">
                                        <span>Errors</span>
                                        <img src={Info} alt="/" />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col">
              <Button
                onClick={handleAddStudent}
                disabled={!records.length ? true : false}
                mode="excel"
              >
                Import Students
              </Button>
              <Button onClick={handleButtonClick}>
                <img src={Validate} alt="" />
                Validate
              </Button>
              <Button onClick={handleClear} mode="clear" />

              <Button onClick={downloadExcelContainsError} mode="excel">
                Export error Data
              </Button>
            </div>
            <div className="col import-excel-data__button">
              <Button mode="excel" onClick={downloadExcel}>
                Generate Template XLS File
              </Button>
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </ReactModal>
    </>
  );
};

export default StudentBasicDataImport;
