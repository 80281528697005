import { useContext, useEffect, useState } from "react";

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import Feeds from "../../images/Feeds.svg";
import Announcements from "../../images/Announcements.svg";
import AccountsLogo from "../../images/AccountsLogo.svg";

import List from "../../images/List.svg";
import Excel from "../../images/Excel.svg";
import Settings from "../../images/Settings.svg";
import UserRights from "../../images/UserRights.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Chat from "../../images/Chat.svg";
import useInstitutionConfiguration from "../../customhooks/useInstitutionConfiguration";
import { useLazyQuery } from "@apollo/client";
import { GetNotAllocatedIds } from "../../queries/institution/configuration/query/SoftwareConfig";
import {
  GetNotAllocatedIdsData,
  GetNotAllocatedIdsVars,
  TableType,
} from "../../utils/Query.Types";
import useToken from "../../customhooks/useToken";
import Customer from "../../images/Customer.svg";
import Institution from "../../images/Institution.svg";
import Templates from "../../images/Templates.svg";
import Allocation from "../../images/Allocation.svg";
import Master_Configuration from "../../images/Master_Configuration.svg";
import Reports from "../../images/GraphView.svg";

import LoginStatus from "../../images/Login Status.svg";

import useChannelConfigs from "../Modules/Chat/CustomHooks/useChannelConfigs";
import useEmpDetailsById from "../../customhooks/useEmpDetailsById";
import { AppContext } from "../../context/context";
import useInstModules from "../../customhooks/useInstModules";

export const handleSignOut = () => {
  signOut(auth)
    .then(() => (window.location.href = "/"))
    .catch((error) => {
      alert(error);
    });
};

const Sidebar = () => {
  const location = useLocation();
  const isChatRoute = location.pathname.includes("/chat");
  const isInstRoute = location.pathname.includes("/institution");
  const { InstModuleData } = useInstModules();
  const accountsFlag =
    InstModuleData &&
    InstModuleData.data &&
    InstModuleData.data.GetMstInstModules.inst_module_details?.find(
      (res) => res.eduate_module_details.Name === "ACCOUNTS"
    )?.is_module_enabled;

  const { instId, custId } = useParams();
  const { token } = useToken();

  const { state } = useContext(AppContext);

  const { employeeFormData } = useEmpDetailsById(true);

  const { enableAnnouncement, enableFeeds, enableClassLevelChannels } =
    useChannelConfigs();

  const [GetNotAllocated, { data }] = useLazyQuery<
    GetNotAllocatedIdsData,
    GetNotAllocatedIdsVars
  >(GetNotAllocatedIds, {
    variables: {
      inst_id: instId!,
      table: TableType.TABLE_NONE,
      table_id: 0,
      token,
    },
  });
  const [navString, setNavString] = useState(
    `/${instId}/chat/config/department`
  );

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  useEffect(
    () => {
      if (token && USE_DEPARTMENT_KEY === false) {
        GetNotAllocated().then(({ data }) => {
          if (data && data.GetNotAllocatedIds) {
            if (
              USE_DEPARTMENT_KEY !== undefined &&
              USE_BRANCH_KEY !== undefined &&
              USE_CLASS_KEY !== undefined &&
              USE_SEMESTER_KEY !== undefined &&
              USE_SECTION_KEY !== undefined
            )
              if (USE_DEPARTMENT_KEY === false && USE_BRANCH_KEY) {
                setNavString(
                  `${navString}/${data.GetNotAllocatedIds.dept_id}/branch`
                );
              } else if (USE_CLASS_KEY) {
                setNavString(
                  `${navString}/${data.GetNotAllocatedIds.dept_id}/branch/${data.GetNotAllocatedIds.branch_id}/class`
                );
              } else if (USE_SEMESTER_KEY) {
                setNavString(
                  `${navString}/${data.GetNotAllocatedIds.dept_id}/branch/${data.GetNotAllocatedIds.branch_id}/class/${data.GetNotAllocatedIds.class_id}/semester`
                );
              } else if (USE_SECTION_KEY) {
                setNavString(
                  `${navString}/${data.GetNotAllocatedIds.dept_id}/branch/${data.GetNotAllocatedIds.branch_id}/class/${data.GetNotAllocatedIds.class_id}/semester/${data.GetNotAllocatedIds.semester_id}/section`
                );
              }
          }
        });
      }
    },
    // eslint-disable-next-line
    [
      token,
      GetNotAllocated,
      data,
      USE_DEPARTMENT_KEY,
      USE_BRANCH_KEY,
      USE_CLASS_KEY,
      USE_SEMESTER_KEY,
      USE_SECTION_KEY,
    ]
  );

  return (
    <>
      <div className="sidebar g-0">
        <div className="sidebar__menu">
          <ul>
            {isChatRoute ? (
              <>
                {enableClassLevelChannels &&
                state.claims &&
                state.claims.EMPLOYEE ? (
                  <li>
                    <NavLink to={`/${instId}/chat/informationcenter`}>
                      <span>
                        <img src={Chat} alt="/" />
                        Information Center
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {enableFeeds && state.claims && state.claims.EMPLOYEE ? (
                  <li>
                    <NavLink to={`/${instId}/chat/feeds`}>
                      <span>
                        <img src={Feeds} alt="/" />
                        MyCampus Feeds
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {enableAnnouncement && state.claims && state.claims.EMPLOYEE ? (
                  <li>
                    <NavLink to={`/${instId}/chat/announcements`}>
                      <span>
                        <img src={Announcements} alt="/" />
                        Announcements
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                {state.claims && state.claims.EMPLOYEE ? (
                  <>
                    <li>
                      <NavLink to={`/${instId}/chat/student/list`}>
                        <span>
                          <img src={List} alt="/" />
                          Student List
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/${instId}/chat/student/loginstatus`}>
                        <span>
                          <img src={LoginStatus} alt="/" />
                          Login Status
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/${instId}/chat/bulk_profile_upload`}>
                        <span>
                          <img src={LoginStatus} alt="/" />
                          Bulk Profile pic Upload
                        </span>
                      </NavLink>
                    </li>
                    {USE_SECTION_KEY ? (
                      <li>
                        <NavLink
                          to={`/${instId}/chat/student/sectionallocation`}
                        >
                          <span>
                            <img src={Allocation} alt="/" />
                            Section Allocation
                          </span>
                        </NavLink>
                      </li>
                    ) : null}
                    {employeeFormData.is_global_user ? (
                      <>
                        {accountsFlag && (
                          <li>
                            <NavLink to={`/${instId}/chat/student/feedetails`}>
                              <span>
                                <img src={AccountsLogo} alt="/" />
                                Fee Details
                              </span>
                            </NavLink>
                          </li>
                        )}

                        <li>
                          <NavLink to={`/${instId}/chat/student/templates`}>
                            <span>
                              <img src={Templates} alt="/" />
                              Templates
                            </span>
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <NavLink to={`/${instId}/chat/importdata`}>
                        <span>
                          <img src={Excel} alt="/" />
                          Import Data
                        </span>
                      </NavLink>
                    </li>
                  </>
                ) : null}

                {employeeFormData.is_global_user ||
                (state.claims && state.claims.EDUATE) ? (
                  <li>
                    <NavLink to={`/${instId}/chat/userrights`}>
                      <span>
                        <img src={UserRights} alt="/" />
                        User Rights
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {state.claims &&
                state.claims.EMPLOYEE &&
                employeeFormData.is_global_user ? (
                  <>
                    <li>
                      {/* <NavLink to="/chat/config"> */}
                      <NavLink to={navString}>
                        <span>
                          <img src={Master_Configuration} alt="/" />
                          Master Configurations
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      {/* <NavLink to="/chat/config"> */}
                      <NavLink to={`/${instId}/chat/reports`}>
                        <span>
                          <img src={Reports} alt="/" />
                          Reports
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      {/* <NavLink to="/chat/config"> */}
                      <NavLink to={`/${instId}/chat/instconfiguration`}>
                        <span>
                          <img src={Settings} alt="/" />
                          Configurations
                        </span>
                      </NavLink>
                    </li>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <li>
                  <NavLink to={`/customer`}>
                    <span>
                      <img src={Customer} alt="/" />
                      Customer
                    </span>
                  </NavLink>
                </li>

                {isInstRoute && (
                  <>
                    <li>
                      <NavLink to={`/${custId}/institution`}>
                        <span>
                          <img src={Institution} alt="/" />
                          Institution
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}
                <li>
                  <NavLink to="/configurations">
                    <img src={Settings} alt="/" />

                    <span>Configurations</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
