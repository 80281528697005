import React, { useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import { TextField, Tooltip } from "@mui/material";
import { useStyles } from "../../../../styles/TooltipStyles";
import Compare from "../../../../../images/Compare.svg";
import MembersList from "../../../Chat/InformationCenter/Channel/MembersList";
import MessageList from "../../../Chat/InformationCenter/Channel/MessageList";
import MemberCount from "../../../Chat/InformationCenter/Channel/MemberCount";
import { channelOptionsType } from "./Index";
import { EMPTY_STRING } from "../../../../../utils/constants";
interface Props {
  channelOptions: channelOptionsType | null;
}
const MessageIndex = ({ channelOptions }: Props) => {
  const [date, setDate] = useState(false);
  const { channelQueryType } = channelOptions || {};
  const tooltipClasses = useStyles();
  const [isMembersListChecked, setIsMembersListChecked] = useState(false);
  console.log(channelQueryType, "channelQueryType");

  const handleMembersListChange = () => {
    setIsMembersListChecked(!isMembersListChecked);
  };

  const [searchMessage, setSearchMessage] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <>
      <input
        type="checkbox"
        id="members-list"
        name=""
        checked={isMembersListChecked}
        onChange={handleMembersListChange}
      />
      <div className="chat-channel__data-block">
        <div className="chat-channel__data-block--title">
          <Title variant="subtitle1">
            {channelOptions
              ? Number(channelOptions.label)
                ? "Me"
                : channelOptions.label
              : EMPTY_STRING}
          </Title>
          <div className="chat-channel__data-block--flex">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => {
                setSearchMessage(e.target.value);
              }}
            />
            {date ? (
              <>
                <TextField
                  className="chat-channel__data-block--textfield"
                  label="Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
                <Tooltip
                  title="Filter By Start & End Date"
                  arrow
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                >
                  <img
                    src={Compare}
                    alt=""
                    className="chat-channel__data-block--compare"
                    onClick={() => setDate(!date)}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <TextField
                  className="chat-channel__data-block--textfield"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
                <Tooltip
                  title="Filter By Date"
                  arrow
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                >
                  <img
                    src={Compare}
                    alt=""
                    className="chat-channel__data-block--compare"
                    onClick={() => setDate(!date)}
                  />
                </Tooltip>

                <TextField
                  className="chat-channel__data-block--textfield"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </>
            )}

            {channelOptions ? (
              channelOptions.label !== "Announcements" &&
              channelOptions.label !== "My Campus Activities" ? (
                isNaN(Number(channelOptions.label)) ? (
                  <MemberCount isMembersListChecked={isMembersListChecked} />
                ) : null
              ) : null
            ) : null}
          </div>
        </div>
        <div className="chat-channel__data-block--frame">
          <div
            className={`chat-channel__data-block--left ${
              isMembersListChecked ? "shift-left" : ""
            }`}
          >
            <MessageList
              searchMessage={searchMessage}
              endDate={endDate}
              startDate={startDate}
              channel={channelQueryType!}
            />
          </div>
          <div
            className={`chat-channel__data-block--right ${
              isMembersListChecked ? "expand-right" : ""
            }`}
          >
            <MembersList individualMsgEnable={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageIndex;
