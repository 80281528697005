import React, { useContext, useEffect, useRef, useState } from "react";
import FileAttach from "../../../../images/BrowseFiles.svg";

import Close from "../../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  InstitutionConfigurationTypes,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import useInstDetails from "../../../../customhooks/useInstDetails";
import {
  AddChannelmessageData,
  AddChannelmessageVars,
  ChannelDataByIdVars,
  MessageDataByIdData,
} from "./Types";
import {
  GetChannelMessages,
  GetMessageById,
} from "../../../../queries/chat/feeds/list";
import { msgType } from "../../../../utils/Types";
import useSwConfigData from "../../../../customhooks/useSwConfighData";
import { GlobalPageConfigData } from "../../../../utils/Query.Types";
import {
  AddChannelmessage,
  UpdateChannelMessage,
} from "../../../../queries/chat/mutations";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../../utils/constants";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../utils/MessageModal";
import LoadingModal from "../../../Modals/LoadingModal";
import useChannels from "../CustomHooks/useChannels";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import useEmpDetailsById from "../../../../customhooks/useEmpDetailsById";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  messageId: number;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
  setSent?: React.Dispatch<React.SetStateAction<boolean>>;
  sent?: boolean;
}
const CreateFeed = ({
  setModalFlag,
  operation,
  messageId,
  setMessage,
  setSent,
  sent,
}: Props) => {
  const { token } = useToken();
  const imageRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File[]>([]);

  const [channelMessage, setChannelMessage] = useState("");

  const { employeeFormData } = useEmpDetailsById(true);
  const { user_details } = useLoggedInUserDetails();
  const { instId } = useParams();
  const [msgHeader, setMsgHeader] = useState("");
  const [messageMediaContent, setMediaContent] = useState("");
  const { data } = useChannels(ChannelQueryType.MYCAMPUSFEED);
  const { state } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  const { InstFormData } = useInstDetails();

  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;

  const [GetChannelMessage] = useLazyQuery<
    MessageDataByIdData,
    ChannelDataByIdVars
  >(GetMessageById);

  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);

  const removeFile = (indexToRemove: number) => {
    const fileteredFiles = file.filter((_, index) => index !== indexToRemove);
    //@ts-ignore
    setFile(fileteredFiles);
  };

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      //@ts-ignore
      setFile(files);
    }
  };

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );
  const [AddChannelMsg, { loading: AddLoading }] = useMutation<
    AddChannelmessageData,
    AddChannelmessageVars
  >(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  let filename =
    file.length > 1
      ? file
          .map(
            (f) => `${InstFormData.inst_name}/channels/${channelId}/${f.name}`
          )
          .join(",")
      : `${InstFormData.inst_name}/channels/${channelId}/${
          file.length ? file[0].name : EMPTY_STRING
        }`;
  const handleSubmit = () => {
    let flag = true;
    if (channelMessage === EMPTY_STRING || msgHeader === EMPTY_STRING) {
      alert("Message content cannot be empty");
      return;
    }

    if (file.length === 0 && operation === Operation.CREATE) {
      alert("File not found");
      return;
    }
    // Handle multiple files
    for (const f of file) {
      if (f.type.includes("image/") && f.size > imageSize! * 1024 * 1024) {
        alert("Image file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type.includes("video/") && f.size > videoSize! * 1024 * 1024) {
        alert("Video file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type === "application/pdf") {
        alert("PDF file is not allowed");
        flag = false;
        break;
      }

      if (
        f.type === "application/vnd.ms-excel" ||
        f.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        alert("Excel file is not allowed ");
        flag = false;
        break;
      }
    }

    if (flag && operation === Operation.CREATE && file) {
      const sendMsgPromise = AddChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: file && file.length ? filename : "",
            msg_channel_id: channelId?.toString()!,
            inst_id: instId!,
            msg_header: msgHeader,
          },
          user_details,
        },

        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              direction: Direction.ASC,
              input: {
                channel_id: channelId,
                end_date: toIsoDate(DEFAULT_TIME),
                query_type: MsgChannelType.MY_CAMPUS_FEEDS,
                start_date: toIsoDate(DEFAULT_TIME),
              },
              inst_id: instId ? instId : state.InstId.toString(),
              last: 50,
              messageContent: EMPTY_STRING,
              sortBy: SortBy.CREATED_AT,
              token,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }) => {
        if (data) {
          const uploadFilePromise =
            file && file.length
              ? file.length > 1
                ? Promise.all(
                    file.map((f, index) =>
                      handleUploadAndDownloadFile(
                        f,
                        `${InstFormData.inst_name}/channels/${channelId}/${
                          data.AddChannelMessage.id
                        }_${index}.${
                          f.name.split(".")[f.name.split(".").length - 1]
                        }`,
                        setProgress,
                        false
                      )
                    )
                  )
                : handleUploadAndDownloadFile(
                    file[0],
                    `${InstFormData.inst_name}/channels/${channelId}/${
                      data.AddChannelMessage.id
                    }.${
                      file[0].name.split(".")[
                        file[0].name.split(".").length - 1
                      ]
                    }`,
                    setProgress,
                    false
                  )
              : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setSent?.(!sent);
                setChannelMessage(EMPTY_STRING);
                setFile([]);
                setModalFlag(false);
                setMessage({
                  flag: true,
                  message: "Feed Created Successfully",
                  operation: Operation.CREATE,
                });
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    } else if (flag && operation === Operation.UPDATE) {
      const sendMsgPromise = UpdateChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content:
              file && file.length ? filename : messageMediaContent,
            msg_header: msgHeader,
          },
          user_details,
          msg_id: messageId,
        },
        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              direction: Direction.ASC,
              input: {
                channel_id: channelId,
                end_date: toIsoDate(DEFAULT_TIME),
                query_type: MsgChannelType.MY_CAMPUS_FEEDS,
                start_date: toIsoDate(DEFAULT_TIME),
              },
              inst_id: instId ? instId : state.InstId.toString(),
              last: 50,
              messageContent: EMPTY_STRING,
              sortBy: SortBy.CREATED_AT,
              token,
            },
          },
        ],
      });
      sendMsgPromise.then(({ data }) => {
        if (data) {
          const uploadFilePromise = file
            ? Promise.all(
                file.map((f, index) =>
                  handleUploadAndDownloadFile(
                    f,
                    `${InstFormData.inst_name}/channels/${channelId}/${
                      data.UpdateChannelMessage.id
                    }_${index}.${
                      f.name.split(".")[f.name.split(".").length - 1]
                    }`,
                    setProgress,
                    false
                  )
                )
              )
            : false;

          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setSent?.(!sent);
                setChannelMessage(EMPTY_STRING);
                setFile([]);
                setModalFlag(false);
                setMessage({
                  flag: true,
                  message: "Feed Updated Successfully",
                  operation: Operation.CREATE,
                });
              }
            })

            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });
    }
  };

  useEffect(() => {
    if (messageId && operation === Operation.UPDATE && token) {
      GetChannelMessage({
        variables: {
          token,
          id: messageId,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setMsgHeader(data.node.msg_header);
          setChannelMessage(data.node.msg_content);
          setMediaContent(data.node.msg_media_content);
        }
      });
    }
  }, [messageId, operation, GetChannelMessage, token]);

  const handleClear = () => {
    setMsgHeader("");
    setChannelMessage("");
    setFile([]);
  };

  return (
    <>
      <div className="create-feeds">
        <div className="create-feeds--title">
          <Title>
            {operation === Operation.UPDATE ? "Update Feed" : "Create Feed"}
          </Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="create-feeds--data">
          <div className="create-feeds--selected"></div>
          <div className="label-grid">
            <Label>Feed Title</Label>
            <Input
              value={msgHeader}
              onChange={(e) => setMsgHeader(e.target.value)}
              autoFocus
            />
            <Label>Description</Label>
            <ReactQuill
              value={channelMessage}
              onChange={setChannelMessage}
              modules={formattingToolbarOptions}
              formats={formatOptions}
            />
          </div>

          <>
            {operation === Operation.UPDATE ? (
              <div className="create-feeds--logo-grid">
                <div></div>
                <b className="nodata">
                  Kindly note that in order to update any attachments, you'll
                  need to delete the existing message and recreate it with the
                  updated attachments.
                </b>
              </div>
            ) : (
              <div className="create-feeds--logo-grid">
                <Label>Attachments</Label>
                {file && (
                  <ul className="create-feeds--attachments">
                    {file.map((f, index) => (
                      <li key={index}>
                        {f.name}
                        <img
                          src={Close}
                          alt="Close"
                          onClick={() => removeFile(index)}
                          className="close-icon"
                        />
                      </li>
                    ))}
                  </ul>
                )}

                <label className="create-feeds--browse">
                  <img src={FileAttach} alt="/" className="browse-icon" />
                  Browse
                  <input
                    type="file"
                    className="create-feeds--browse--input"
                    ref={imageRef!}
                    multiple
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImagePreview(e)
                    }
                    accept="image/png, image/jpeg, image/jpg, video/*"
                  />
                </label>
              </div>
            )}
          </>
        </div>
        <Button
          mode="save"
          onClick={handleSubmit}
          disabled={employeeFormData.can_send_feeds === false || AddLoading}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button
          mode="cancel"
          onClick={() => {
            handleClear();
            setModalFlag(false);
          }}
        />
      </div>

      <LoadingModal flag={AddLoading || UpdateLoading} />
    </>
  );
};

export default CreateFeed;
