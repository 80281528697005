import React, { useContext, useEffect, useState } from "react";
import DashBoard from "./components/Layouts/Index";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Customer from "./components/Modules/Masters/Customer/Index";
import Institution from "./components/Modules/Masters/Institution/Index";
import {
  ParsedToken,
  getIdTokenResult,
  onAuthStateChanged,
} from "firebase/auth";
import { AppContext } from "./context/context";
import { auth } from "./firebase";
import { payloadTypes } from "./context/reducer";
import {
  WINDOW_MAX_ZOOM,
  WINDOW_MIN_HEIGHT,
  WINDOW_MIN_WIDTH,
} from "./utils/constants";
import {
  InstDetailType,
  InstitutionConfigurationTypes,
  Result,
  StudentReportType,
} from "./utils/Enum.types";
import useSwConfigData from "./customhooks/useSwConfighData";
import IdleTimeOutHandler from "./utils/IdleTimeOut";
import Login from "./components/Auth/Login";

import InformationCenter from "./components/Modules/Chat/InformationCenter/Index";
import Configuration from "./components/Modules/Chat/Configurations/Index";
import Page404 from "./components/Layouts/Page404";
import Channel from "./components/Modules/Chat/InformationCenter/Channel/Index";
import ImportData from "./components/Modules/Masters/Student/Import";
import StudentList from "./components/Modules/Masters/Student/Index";
import Feeds from "./components/Modules/Chat/Feeds/Index";

import Announcements from "./components/Modules/Chat/Announcements/Index";
import UserRights from "./components/Modules/Chat/UserRights/Index";
import StudentRoutes from "./components/Modules/Masters/Student/routes/StudentRoutes";
import InstConfiguration from "./components/Modules/Chat/Configurations/InstitutionConfiguration/Index";
import FeeDetails from "./components/Modules/Masters/Student/Dashboard/FeeDetails";
import FetchDetails from "./components/Modules/Chat/FeeDetails/FetchDetails";
import StudentDetails from "./components/Modules/Chat/FeeDetails/StudentDetails";
import Payment from "./components/Modules/Chat/FeeDetails/Payment";
import Templates from "./components/Modules/Templates/TemplatesIndex";
import { StdDemandQueryType } from "./utils/QueryEnums";
import Tags from "./components/Modules/Templates/Tags";
import SectionAllocation from "./components/Modules/Masters/Student/SectionAllocation/SectionAllocation";
import OnlinePayment from "./components/Modules/MobileApp/PayOnline/Index";
import PayFee from "./components/Modules/MobileApp/PayOnline/PayFee";
import StudentListForPayment from "./components/Modules/MobileApp/PayOnline/StudentList";
import StudentLogin from "./components/Modules/Masters/Student/EnableLogin/Index";
import BulkProfilePicUpload from "./components/Modules/Masters/Student/BulkProfilePicUpload";
import Fcm from "./components/Modules/Masters/FututreMessage/Fcm";
import Reports from "./components/Modules/Chat/InformationCenter/Reports/Index";

const EduateChat = () => {
  const { state, dispatch } = useContext(AppContext);

  // eslint-disable-next-line
  const [claims, setClaims] = useState<ParsedToken>({});
  //To restrict zoom in browsers
  const [showWarning, setShowWarning] = useState(false);

  const [showError, setShowError] = useState(false);

  // eslint-disable-next-line
  const [isActive, setIsActive] = useState(true);
  // eslint-disable-next-line
  const [isLogout, setLogout] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(
    () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch({ type: payloadTypes.SET_USER, payload: { user } });

          getIdTokenResult(user).then((IdTokenResult) => {
            const { claims } = IdTokenResult;
            dispatch({ type: payloadTypes.SET_CLAIMS, payload: { claims } });
            setClaims(claims);
          });
        }
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    let tolerance = 1;
    if (window.devicePixelRatio > 1) {
      tolerance = 1 / window.devicePixelRatio;
    }

    if (
      window.innerWidth < WINDOW_MIN_WIDTH * tolerance ||
      window.innerHeight < WINDOW_MIN_HEIGHT * tolerance
    ) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [windowSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.devicePixelRatio > WINDOW_MAX_ZOOM) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [windowSize]);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.INACTIVITY_TIMEOUT
  );

  return (
    <div className="edu-chat">
      <Router>
        <Routes>
          <Route path="/:instId/mobileapp/login" element={<OnlinePayment />} />
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:stdDemandId/paymentprocess"
            element={<PayFee PaymentResult={Result.NOT_STARTED} />}
          />
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:stdDemandId/paymentsuccess"
            element={<PayFee PaymentResult={Result.SUCCESS} />}
          />{" "}
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:stdDemandId/paymentfailure"
            element={<PayFee PaymentResult={Result.FAILURE} />}
          />
          {/* <Route
            path="/mobileapp/proceedpayment"
            element={<Proceed pageType={PaymentProcess.PROCEEDING} />}
          /> */}
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:encryptString/stdlist/mobile"
            element={
              <StudentListForPayment by={StdDemandQueryType.BY_MOBILE_NO} />
            }
          />
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:encryptString/stdlist/studentid"
            element={
              <StudentListForPayment by={StdDemandQueryType.BY_STD_ID} />
            }
          />
          <Route
            path="/:instId/mobileapp/fee/fetchdetails/:encryptString/stdlist/rollno"
            element={
              <StudentListForPayment by={StdDemandQueryType.BY_STD_ROLL_NO} />
            }
          />
        </Routes>
      </Router>
      {showError ? (
        <>
          {window.location.pathname.includes("/mobileapp") ? (
            showError ? null : (
              <div className="desktop-error">
                This application is only available for desktop. Please increase
                the window size Or enable Desktop Site.
              </div>
            )
          ) : null}
        </>
      ) : showWarning ? (
        <>
          {window.location.pathname.includes("/mobileapp") ? (
            showWarning ? null : (
              <div className="desktop-error">
                This application is only available for desktop. Please increase
                the window size Or enable Desktop Site.
              </div>
            )
          ) : null}
        </>
      ) : state.user &&
        state.claims &&
        (window.location.pathname.includes("/fetchdetails") === false ||
          window.location.pathname.includes("/mobileapp") === false) ? (
        <>
          <IdleTimeOutHandler
            onActive={() => setIsActive(true)}
            onIdle={() => setIsActive(false)}
            onLogout={() => setLogout(true)}
            timeOutInterval={
              configData.data
                ? configData.data.GetSwConfigVariables[0].config_integer_value
                : 10
            }
          />
          <Router>
            <DashBoard>
              <Routes>
                <Route path="*" element={<Page404 />} />
                {state.claims.EDUATE ? (
                  <>
                    <Route path="/customer" element={<Customer />} />
                    <Route path="/configurations" element={<Fcm />} />

                    <Route
                      path="/:custId/institution"
                      element={<Institution />}
                    />
                    <Route path="/tags" element={<Tags />} />
                  </>
                ) : null}
                {state.claims.EMPLOYEE || state.claims.EDUATE ? (
                  <Route
                    path="/:instId/chat/userrights"
                    element={<UserRights />}
                  />
                ) : null}
                {state.claims.EMPLOYEE ? (
                  <>
                    <Route
                      path="/:instId/chat/informationcenter"
                      element={<InformationCenter />}
                    />
                    <Route
                      path="/:instId/chat/config/department"
                      element={<Configuration instType={InstDetailType.DEPT} />}
                    />
                    <Route
                      path="/:instId/chat/config/department/:deptId/branch"
                      element={
                        <Configuration instType={InstDetailType.BRANCH} />
                      }
                    />
                    <Route
                      path="/:instId/chat/config/department/:deptId/branch/:branchId/class"
                      element={
                        <Configuration instType={InstDetailType.CLASS} />
                      }
                    />
                    <Route
                      path="/:instId/chat/config/department/:deptId/branch/:branchId/class/:classId/semester"
                      element={
                        <Configuration instType={InstDetailType.SEMESTER} />
                      }
                    />
                    <Route
                      path="/:instId/chat/config/department/:deptId/branch/:branchId/class/:classId/semester/:semId/section"
                      element={
                        <Configuration instType={InstDetailType.SECTION} />
                      }
                    />
                    <Route
                      path="/:instId/chat/instconfiguration"
                      element={<InstConfiguration />}
                    />
                    <Route path="/:instId/chat/reports" element={<Reports />} />
                    <Route
                      path="/:instId/chat/informationcenter/:entryId/channel"
                      element={<Channel />}
                    />
                    <Route
                      path="/:instId/chat/importdata"
                      element={<ImportData />}
                    />
                    <Route path="/:instId/chat/feeds" element={<Feeds />} />
                    <Route
                      path="/:instId/chat/bulk_profile_upload"
                      element={<BulkProfilePicUpload />}
                    />

                    <Route
                      path="/:instId/chat/announcements"
                      element={<Announcements />}
                    />
                    <Route
                      path="/:instId/chat/student/list"
                      element={<StudentList />}
                    />
                    <Route
                      path="/:instId/chat/student/sectionallocation"
                      element={<SectionAllocation />}
                    />
                    <Route
                      path="/:instId/chat/student/loginstatus"
                      element={
                        <StudentLogin queryType={StudentReportType.GENERAL} />
                      }
                    />
                    <Route
                      path="/:instId/chat/student/feedetails"
                      element={<FeeDetails />}
                    />
                    <Route
                      path="/:instId/chat/student/templates"
                      element={<Templates />}
                    />
                  </>
                ) : null}

                {state.claims.STUDENT ? (
                  <Route
                    path="/:instId/student/*"
                    element={<StudentRoutes />}
                  />
                ) : null}
              </Routes>
            </DashBoard>
          </Router>
        </>
      ) : (
        <Router>
          <Routes>
            <Route path="*" element={<Page404 />} />

            <Route path="/" element={<Login />} />

            <Route
              path="/:instId/chat/fee/fetchdetails"
              element={<FetchDetails />}
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:encryptString/stdlist/mobile"
              element={<StudentDetails by={StdDemandQueryType.BY_MOBILE_NO} />}
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:encryptString/stdlist/all"
              element={
                <StudentDetails by={StdDemandQueryType.ALL_STD_DEMANDS} />
              }
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:encryptString/stdlist/studentid"
              element={<StudentDetails by={StdDemandQueryType.BY_STD_ID} />}
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:encryptString/stdlist/rollno"
              element={
                <StudentDetails by={StdDemandQueryType.BY_STD_ROLL_NO} />
              }
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:stdDemandId/paymentprocess"
              element={<Payment PaymentResult={Result.NOT_STARTED} />}
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:stdDemandId/:transactionId/paymentsuccess"
              element={<Payment PaymentResult={Result.SUCCESS} />}
            />
            <Route
              path="/:instId/chat/fee/fetchdetails/:stdDemandId/:transactionId/paymentfailure"
              element={<Payment PaymentResult={Result.FAILURE} />}
            />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default EduateChat;
