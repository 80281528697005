import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../../stories/Input/Input";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import Modal from "react-modal";

import useMasterTableJson from "../../../Masters/json/useTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  Direction,
  MsgChannelType,
  PageFor,
  SortBy,
} from "../../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  ImageTypes,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../../utils/constants";
import {
  getFileName,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import { responseType, TableHeaderProps } from "../../../../../utils/Types";
import { Title } from "../../../../../stories/Title/Title";
import { AppContext } from "../../../../../context/context";
import Close from "../../../../../images/Close.svg";
import { CommonNodeIdVars, EmpDataDetails } from "../../UserRights/Types";
import { Label } from "../../../../../stories/Label/Label";
import { useLazyQuery } from "@apollo/client";
import {
  ChannelDataByIdVars,
  GetChannelMessagesData,
  GetChannelMessagesReportVars,
  MessageDataByIdData,
  MessageEdge,
  MessageNode,
} from "../../Feeds/Types";
import {
  GetChannelMessagesForReports,
  GetMessageById,
} from "../../../../../queries/chat/feeds/list";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useServerDateandTime from "../../../../../customhooks/useServerDateandTimeWithoutToken";
import { EmpDetailsById } from "../../../../../queries/userrights";
import { MessageModalStyles } from "../../../../styles/ModalStyles";
import { MessageData } from "../../Feeds/Index";
import axios from "axios";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import { Autocomplete, TextField } from "@mui/material";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../../customhooks/useDropDown";
import { Keys } from "../../../../../utils/Enum.keys";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { listAutoCompleteTextStyles } from "../../../../styles/AutocompleteStyles";
import { ListAutoCompleteStyles } from "../../../../../styles/AutocompleteListStyles";
import { Button } from "../../../../../stories/Button/Button";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const DayWiseReports = ({ pageType, setModalFlag }: Props) => {
  const classes = useDataGridStyles();
  const { token } = useToken();
  const { instId } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const listClasses = ListAutoCompleteStyles();
  const textClasses = listAutoCompleteTextStyles();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );

  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const { DayWiseReport } = useMasterTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const [startDate, setStartDate] = useState(TODAY_DATE);
  const [endDate, setEndDate] = useState(TODAY_DATE);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { state } = useContext(AppContext);
  const [rowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const { InstFormData } = useInstDetails();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const { serverDate } = useServerDateandTime();
  const [messageId, setMessageId] = useState(0);
  const [GetChannelMessage, { data }] = useLazyQuery<
    MessageDataByIdData,
    ChannelDataByIdVars
  >(GetMessageById);
  const [GetMessages, { data: MessagesData, fetchMore, loading, variables }] =
    useLazyQuery<GetChannelMessagesData, GetChannelMessagesReportVars>(
      GetChannelMessagesForReports
    );
  const [channelMessage, setChannelMessage] = useState<MessageNode>();

  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };
  const [GetEmpDetails, EmpData] = useLazyQuery<
    EmpDataDetails,
    CommonNodeIdVars
  >(EmpDetailsById);
  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://hnlm1fiypb.execute-api.ap-south-1.amazonaws.com/downloadObjectFromESandeshBucket?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        if (response.data !== null) {
          axios.get(response.data, { responseType: "blob" }).then(() => {
            setDownloadUrl((prevDownloadUrl) =>
              prevDownloadUrl.map((messageData) => {
                if (getFileName(mediaContent) === messageData.downloadKey) {
                  return { ...messageData, url: response.data };
                } else return messageData;
              })
            );
          });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (serverDate) {
      setStartDate(serverDate);
      setEndDate(serverDate);
    }
  }, [serverDate]);

  useEffect(() => {
    if (MessagesData && !loading && token) {
      const uniqueIds = [
        ...new Set(
          MessagesData.GetChannelMessages.edges.map(
            (res) => res.node.sent_user_id
          )
        ),
      ];

      GetEmpDetails({ variables: { ids: uniqueIds, token } });
    }
  }, [MessagesData, loading, token, GetEmpDetails]);
  useEffect(() => {
    if (messageId && token) {
      GetChannelMessage({
        variables: {
          token,
          id: messageId,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setChannelMessage(data.node);
        }
      });
    }
  }, [messageId, GetChannelMessage, token]);
  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        // Split the media content by commas and map over each item
        return message.node.msg_media_content
          .split(",")
          .map((content, index) => {
            const fileExtension = content.split(".").pop();
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}${
              index > 0 ? `_${index}` : ""
            }.${fileExtension}`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,
              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
      });

    setDownloadUrl(messageDataArray);

    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        getDownloadUrl(message.id, message.mediaContent);
      });
    };

    // Fetch download URLs if there is any media content
    if (messageDataArray.length > 0) fetchDownloadUrls();
  }, [allMessages, InstFormData.inst_name]);

  useEffect(() => {
    if (token) {
      GetMessages({
        variables: {
          token,
          inst_id: instId ? instId : state.InstId.toString(),
          input: {
            query_type:
              USE_SECTION_KEY && sectionSelected
                ? MsgChannelType.MSGS_BY_ENTRY_ID
                : USE_SEMESTER_KEY && semesterSelected
                ? MsgChannelType.MSGS_BY_ENTRY_ID
                : USE_CLASS_KEY && classSelected
                ? MsgChannelType.MSGS_BY_ENTRY_ID
                : MsgChannelType.ALL_CHANNELS,
            channel_id: 0,
            start_date: toIsoDate(startDate),
            end_date: toIsoDate(endDate),
            entry_id:
              USE_SECTION_KEY && sectionSelected
                ? sectionSelected.value
                : USE_SEMESTER_KEY && semesterSelected
                ? semesterSelected.value
                : USE_CLASS_KEY && classSelected
                ? classSelected.value
                : 0,
          },
          first: rowsPerPage,
          direction: Direction.ASC,
          after: null,
          sortBy: SortBy.CREATED_AT,
          messageContent: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [
    token,
    MessagesData,
    startDate,
    sectionSelected,
    semesterSelected,
    classSelected,
    endDate,
    GetMessages,
  ]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetChannelMessages.edges;
                const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetChannelMessages.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetChannelMessages: {
                    edges: [...allMessages, ...newEdges],
                    pageInfo,
                    totalCount: MessagesData
                      ? MessagesData.GetChannelMessages.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );

  const dynamicHeaders: TableHeaderProps[] = DayWiseReport.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(() => {
    if (MessagesData && EmpData && EmpData.data && !loading) {
      const nodes = EmpData.data.nodes || [];

      // Create a mapping of employee IDs to names
      const empDetailsMap = nodes.reduce((acc, emp) => {
        if (emp) {
          acc[emp.id] = emp.emp_name;
        }
        return acc;
      }, {} as Record<string, string>);

      // Map messages and replace sender ID with employee name
      const newData = MessagesData.GetChannelMessages.edges;
      const updatedNewData = newData.map((newRow) => {
        const empName = empDetailsMap[newRow.node.sent_user_id] || EMPTY_STRING;
        return {
          ...newRow,
          node: {
            ...newRow.node,
            emp_name: empName,
          },
        };
      });

      // Set all messages and formatted rows
      setAllMessages(updatedNewData);
      setRows(
        updatedNewData.map(({ node }, index) => ({
          id: index + 1,
          msg_date: toStandardDate(node.msg_date),
          sender_name: node.emp_name,
          channel_name: node.channel_details
            ? node.channel_details.channel_type === "INDIVIDUAL"
              ? node.channel_details.channel_desc
              : node.channel_details.channel_name
            : EMPTY_STRING,
          user_message: parseMessageContent(node.msg_content),
          user_attachments: node.msg_media_content.length > 0 ? "Yes" : "No",
          message_id: node.id,
        }))
      );

      setEndCursor(MessagesData.GetChannelMessages.pageInfo.endCursor);
    }
  }, [MessagesData, EmpData, loading]);

  const handleClick = (params: GridCellParams) => {
    if (params.field === "channel_name") {
      const msgId = params.row.message_id;
      setMessageId(msgId);
      setMessageModal(true);
    }
  };
  const parsedContent = parseMessageContent(
    data ? data.node.msg_content : EMPTY_STRING
  );
  const isImage = ImageTypes.includes(
    data
      ? data.node.msg_media_content.split(".")[
          data.node.msg_media_content.split(".").length - 1
        ]
      : EMPTY_STRING
  );
  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };
  const combinedById = downLoadUrl.reduce((result, item) => {
    if (!result[item.id]) {
      result[item.id] = [item];
    } else {
      result[item.id].push(item);
    }
    return result;
  }, {} as { [id: number]: MessageData[] });
  const isPdf = data
    ? data.node.msg_media_content.includes(".pdf")
    : EMPTY_STRING;
  const isExcel = data
    ? data.node.msg_media_content.includes(".xls") ||
      (data && data.node.msg_media_content.includes(".xlsx"))
    : EMPTY_STRING;
  const isVideo = data
    ? data.node.msg_media_content.includes(".mp4")
    : EMPTY_STRING;
  return (
    <>
      <div className="user-rights__login-user">
        <div className="user-rights__login-user--title">
          <Title variant="subtitle1">
            {pageType === PageFor.GENERAL ? "Day Wise Report" : "User List"}
          </Title>
          {pageType === PageFor.MODAL && (
            <img src={Close} alt="" onClick={() => setModalFlag(false)} />
          )}
        </div>

        <div className="row g-0 user-rights__select">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2 ">
              <Autocomplete
                classes={listClasses}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-2 ">
              <Autocomplete
                classes={listClasses}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CLASS_KEY ? (
            <div className="col-2 ">
              <Autocomplete
                classes={listClasses}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}

          {USE_SEMESTER_KEY ? (
            <div className="col-2 ">
              <Autocomplete
                classes={listClasses}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_SECTION_KEY ? (
            <div className="col-2">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={listClasses}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {/* <Input
              id="search"
              type="text"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              onKeyDown={handleFormEvent}
            /> */}
          <div className="col"></div>
          <div className="col-4 flex-end">
            <TextField
              id="search"
              label="Start Date"
              type="date"
              placeholder="Search ..."
              value={toInputStandardDate(startDate)}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              onKeyDown={handleFormEvent}
              InputLabelProps={{ shrink: true }}
              className="user-rights__select--textfield"
            />

            <TextField
              id="search"
              type="date"
              label="End Date"
              value={toInputStandardDate(endDate)}
              placeholder="Search ..."
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              inputProps={{
                max: serverDate,
              }}
              onKeyDown={handleFormEvent}
              className="user-rights__select--textfield"
            />
          </div>
        </div>
        <div className={`user-rights__login-user--tableblock ${classes.root}`}>
          <DataGridPro
            columns={columns.filter(
              ({ field }) =>
                (field !== "user_actions" && pageType === PageFor.MODAL) ||
                pageType === PageFor.GENERAL
            )}
            rows={rows}
            disableRowSelectionOnClick
            onCellClick={handleClick}
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>
        <div className="flex-end">
          <div className="student-total-count">
            Total Messages :
            <b>{MessagesData && MessagesData.GetChannelMessages.totalCount}</b>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={MessageModalStyles}
        ariaHideApp={false}
      >
        <div className="user-rights__detailed-view">
          <div className="user-rights__login-user--title">
            <Title variant="subtitle1">Detailed View</Title>
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
          <div className="user-rights__detailed-view--block">
            {data && (
              <p
                dangerouslySetInnerHTML={{
                  __html: parsedContent,
                }}
              />
            )}
            {combinedById[data?.node.id!]?.map(({ mediaContent, url }) => {
              if (url) {
                if (isImage) {
                  return (
                    <a key={url} href={url}>
                      <img
                        src={url}
                        alt="/"
                        className="user-rights__detailed-view--file-image"
                      />
                    </a>
                  );
                } else if (isPdf || isExcel) {
                  return (
                    <a
                      key={url}
                      href={url}
                      onClick={() => handleFileDownload(url)}
                    >
                      {getFileName(mediaContent)}
                    </a>
                  );
                } else if (isVideo) {
                  return (
                    <video
                      key={url}
                      width="320"
                      height="240"
                      controls
                      src={url}
                    >
                      {mediaContent}
                      Your browser does not support the video tag.
                    </video>
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>

          <Button
            mode="cancel"
            onClick={() => setMessageModal(!messageModal)}
          />
        </div>
      </Modal>
    </>
  );
};

export default DayWiseReports;
