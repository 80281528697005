import React, { useState } from "react";
import Navbar from "../../../../Layouts/Navbar";
import { licenseTypes } from "../../../../../utils/constants";
import Sidebar from "../../../../Layouts/Sidebar";
import { Title } from "../../../../../stories/Title/Title";
import { Operation, PageFor } from "../../../../../utils/Enum.types";

import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import DayWiseReports from "./DayWiseReport";

export interface ModalType {
  operation: Operation;
  id: number;
  flag: boolean;
}
const Reports = () => {
  const [value, setValue] = useState(0);

  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="user-rights">
        <Sidebar />
        <div className="user-rights__frame">
          <Title>Reports</Title>
          <div className="user-rights__frame--data">
            <div className="user-rights__frame--tabs">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Day-Wise" value={0} {...a11yProps(0)} />
              </Tabs>
            </div>
            <div className="user-rights__frame--tab-panel">
              <TabPanel value={value} index={0} dir={theme.direction}>
                <DayWiseReports
                  pageType={PageFor.GENERAL}
                  setModalFlag={setModalFlag}
                />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
