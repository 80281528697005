import dayjs from "dayjs";
import { GENDER, Operation } from "./Enum.types";
import { AcctOnlinePaymentQueryType } from "./QueryEnums";
import { msgType } from "./Types";
import ExcelJS, { Borders } from "exceljs";

export const TEXT_FONT_FAMILY = "Josefin Sans,sans-serif !important";

export enum licenseTypes {
  INSTITUTION = "INSTITUTION",
  CHANNEL = "CHANNEL",
  CUSTOMER = "CUSTOMER",
}

export enum INSTCONFIG {
  DEPARTMENT = "Departments",

  CATEGORIES = "Categories",
}
export enum payloadTypes {
  SET_STUDENT_ID = "SET_STUDENT_ID",
  SET_USER = "SET_USER",
  SET_INST_ID = "SET_INST_ID",
  SET_CLAIMS = "SET_CLAIMS",
  SET_AUTH_ID = "SET_AUTH_ID",
  SET_ACCOUNT_MAIN_ID = "SET_ACCOUNT_MAIN_ID",
  SET_ALUMINI_STUDENT_ID = "SET_ALUMINI_STUDENT_ID",
  SET_RECEPIT_ID = "SET_RECEPIT_ID",
  SET_RECEIPT_STUDENT_ID = "SET_RECEIPT_STUDENT_ID",
  SET_ACCOUNT_LEDGER_ID = "SET_ACCOUNT_LEDGER_ID",
  SET_REGISTER_TYPE = "SET_REGISTER_TYPE",
  SET_DATE = "SET_DATE",
  SET_FEE_RECEIPT_BOOK_NAME = "SET_FEE_RECEIPT_BOOK_NAME",
  SET_BANK_RECONCILATION_NAME = "SET_BANK_RECONCILATION_NAME",
  SET_PARENT_ID = "SET_PARENT_ID",
  SET_TEST_CONDUCT_ID = "TEST_CONDUCT_ID",
  SET_STAFF_ID = "SET_STAFF_ID",
  SET_CLASSIFICATION_ID = "SET_CLASSIFICATION_ID",
  SET_BOOK_ID = "SET_BOOK_ID",
  RESET = "RESET",
  SET_CHANGE_OF_BRANCH_STUDENT_ID = "SET_CHANGE_OF_BRANCH_STUDENT_ID",
  SET_STD_ENQUIRY_ID = "SET_STD_ENQUIRY_ID",
  SET_EMPLOYEE_ID = "SET_EMPLOYEE_ID",
  SET_SUBJECT_ID = "SET_SUBJECT_ID",
  SET_LEAVE_LEDGER_ID = "SET_LEAVE_LEDGER_ID",
  SET_ACD_DEPT_ID = "SET_ACD_DEPT_ID",
  SET_ACD_BRANCH_ID = "SET_ACD_BRANCH_ID",
  SET_ACD_CLASS_ID = "SET_ACD_CLASS_ID",
  SET_ACD_SEM_ID = "SET_ACD_SEM_ID",
  SET_ACD_SECTION_ID = "SET_ACD_SECTION_ID",
  SET_ALLOTED_ID = "SET_ALLOTED_ID",
  SET_CHANNEL_ID = "SET_CHANNEL_ID",
  SET_ENTRY_ID = "SET_ENTRY_ID",
  SET_ENTRY_LEVEL = "SET_ENTRY_LEVEL",
  SET_SW_ACCT_LDGR_ID = "SET_SW_ACCT_LDGR_ID",
  SET_EMP_LOGIN_ID = "SET_EMP_LOGIN_ID",
  SET_STD_ADM_NO = "SET_STD_ADM_NO",
  SET_CUST_ID = "SET_CUST_ID",
  SET_UNKOWN_ADJUSTMENT_DETAILS_ID = "SET_UNKOWN_ADJUSTMENT_DETAILS_ID",
  SET_UNKOWN_RECEIPT_ID = "SET_UNKOWN_RECEIPT_ID",
  SET_SEARCH_EMPLOYEE_ID = "SET_SEARCH_EMPLOYEE_ID",
  SET_CAMPUS_ID = "SET_CAMPUS_ID",
  SET_MK_CK_ID = "SET_MK_CK_ID",
  SET_MKCK_CREATED_ID = "SET_MKCK_CREATED_ID",
  SET_LEDGER = "SET_LEDGER",
  SET_REGISTER = "SET_REGISTER",
  SET_EMP_INST_ID = "SET_EMP_INST_ID",
  SET_EDUATE_ID = "SET_EDUATE_ID",
  SET_CHANNEL_NAME = "SET_CHANNEL_NAME",
  SET_ACTIVE_FIN_YR = "SET_ACTIVE_FIN_YR",
  SET_STEP_INST_ID = "SET_STEP_INST_ID",
  SET_SUB_USER_TYPE = "SET_SUB_USER_TYPE",
}
export const enum TableHeaders {
  SLNO = "Sl",
  NAME = "Name",
}
export const query = "/query";

export const WINDOW_MIN_WIDTH = 960;
export const WINDOW_MIN_HEIGHT = 600;
export const WINDOW_MAX_ZOOM = 2.5;
export const ROWS_PER_PAGE = 25;

export const EMPTY_STRING = "";

export const INPUT = "INPUT";
export const SELECT = "SELECT";
export const TEXTAREA = "TEXTAREA";
export const BUTTON = "BUTTON";

export const enum InstDetails {
  DEFAULT_DEPT_LABEL = "DEFAULT_DEPT_LABEL",
  DEFAULT_BRANCH_LABEL = "DEFAULT_BRANCH_LABEL",
  DEFAULT_CLASS_LABEL = "DEFAULT_CLASS_LABEL",
  DEFAULT_SEMESTER_LABEL = "DEFAULT_SEMESTER_LABEL",
  DEFAULT_SECTION_LABEL = "DEFAULT_SECTION_LABEL",
}
export const formattingToolbarOptions = {
  toolbar: [
    ["bold", "italic"], // Include the options you want in the toolbar
    ["link"],
    // Headers
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    ["clean"], // Remove formatting
  ],
};

export const formatOptions = [
  "bold",
  "italic",
  "underline",
  "strike", // Formats available
  // "header",
  "list",
  "link",
];

export const emptyMessageType: msgType = {
  flag: false,
  message: "",
  operation: Operation.NONE,
};

export const EMPTY_RESPONSETYPE_OBJECT = {
  label: "",
  value: 0,
  isChecked: false,
};
export const A2_CELL = "A2";
export const A3_CELL = "A3";
export const A4_CELL = "A4";
export const EDUATE_IMG_FORMAT = "png";
export const FIRST_CELL = "A1";
export const PATTERN = "pattern";
export const SOLID = "solid";
export const C4_CELL = "C4";
export const E4_CELL = "E4";
export const FILENAME = "filename";
export const FIRST_INDEX = "@";
export const BORDER_DATA: Partial<Borders> = {
  top: { style: "thin", color: { argb: "FF000000" } },
  left: { style: "thin", color: { argb: "FF000000" } },
  bottom: { style: "thin", color: { argb: "FF000000" } },
  right: { style: "thin", color: { argb: "FF000000" } },
};
export const HEADER_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "e5e7eb" },
};
export const HEADER_ALIGNMENT: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "bottom",
};
export const ADDRESS_ALIGNMENT: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "top",
};
export const TABLE_HEADER_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "334155" },
};
export const TABLE_HEADER_CSS2: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "B2DFEE" },
};
export const TABLE_HEADER_CSS3: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "D4E4F7" },
};
export const TABLE_CSS: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "D9D9D9" },
};
export const FILE_NAME_CSS: ExcelJS.Fill = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "d6e9b7" },
};
export const FILE_NAME_FONT = {
  name: "Arial Rounded MT Bold",
  size: 14,
};

export const TABLE_HEADER_CSS4: ExcelJS.Fill = {
  type: PATTERN,
  pattern: SOLID,
  fgColor: { argb: "F2F2F2" },
};
export const ADDRESS_FONT = {
  name: "Arial Rounded MT Bold",
  size: 11,
};
export const FIN_YEAR_FONT = {
  name: "Arial Rounded MT Bold",
  size: 11,
};
export const HEADER_ALIGNMENT_LEFT: Partial<ExcelJS.Alignment> = {
  horizontal: "left",
  vertical: "middle",
};
export const HEADER_ALIGNMENT_RIGHT: Partial<ExcelJS.Alignment> = {
  horizontal: "right",
  vertical: "middle",
};
export const HEADER_ALIGNMENT_CENTER: Partial<ExcelJS.Alignment> = {
  horizontal: "center",
  vertical: "middle",
};
export const FROZEN_CELLS: Partial<ExcelJS.WorksheetView>[] = [
  {
    state: "frozen",
    xSplit: 0,
    ySplit: 5,
    activeCell: "A6",
  },
];
export const ACC_HEADER_FONT = {
  name: "Century Gothic",
  size: 10,
  bold: true,
  color: { argb: "FFFFFFFF" },
};
export const BLOB_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const ELEMENT = "a";
export const DOWNLOAD = "download";
export const NOTALLOCATED = "---";
export const HYPHEN = "-";

export const DELETE = "DELETE";

export enum AccountentType {
  ACCOUNTANT = "ACCOUNTANT",
  CASHIER = "CASHIER",
  CLERK = "CLERK",
  TEACHER = "TEACHER",
  SYS_ADMIN = "SYS_ADMIN",
}

export const ImageTypes = [
  "webp",
  "apng",
  "avif",
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
];

export const GenderOptions = ["Male", "Female"];

export const EmpStatusOptions = ["Active", "Non-Active"];

export const convertMB2Bytes = 1024 * 1024;

export const ONLINE_TRANSACTION_ROWS_PER_PAGE = 11;
export const TODAY_DATE = dayjs().format("YYYY-MM-DD");

// Error code for success
export const E000 = "E000";

export const transactionStatusOptions = [
  {
    label: "All",
    value: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
  },
  {
    label: "Success",
    value: AcctOnlinePaymentQueryType.ON_PY_SUCCESS_RECORDS,
  },
  {
    label: "In Complete",
    value: AcctOnlinePaymentQueryType.ON_PY_IN_COMPLETE_RECORDS,
  },
  {
    label: "Failed",
    value: AcctOnlinePaymentQueryType.ON_PY_FAILED_RECORDS,
  },
];

export const StdFeeDetailHeaders = [
  "Sl",
  "Student Id",
  "Name",
  "Father Name",
  "Mother Name",
  "Roll No",
  "Place",
  "Class Description",
  "Mobile No",
  "Demand",
  "Paid",
  "Balance",
];

export const GenderEnumOptions = [
  {
    label: "Male",
    value: GENDER.MALE,
  },
  {
    label: "Female",
    value: GENDER.FEMALE,
  },
];

export const HEADER_TEXT_ALIGN = "center";
export const SLNO_TEXT_ALIGN = "center";
export const MARKS_TEXT_ALIGN = "center";
