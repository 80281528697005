import { gql } from "@apollo/client";
export const DeleteChannelMessage = gql`
  mutation DeleteChannelMessage(
    $token: String!
    $msg_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteChannelMessage(
      token: $token
      msg_id: $msg_id
      user_details: $user_details
    )
  }
`;
export const AddChannelmessage = gql`
  mutation AddChannelmessage(
    $token: String!
    $input: CreateChannelMessageInput!
    $user_details: SubscribedUser!
  ) {
    AddChannelMessage(
      token: $token
      input: $input
      user_details: $user_details
    ) {
      id
      msg_content
      msg_media_content
      msg_media_type
    }
  }
`;
export const SendFcmMsg = gql`
  mutation SendFcmMsg($token: String!, $msg: String!) {
    SendFcmMsg(token: $token, msg: $msg)
  }
`;

export const AddIndividualStdMessageWithTags = gql`
  mutation AddIndividualStdMessageWithTags(
    $token: String!
    $input: IndividualStdMsgs!
    $user_details: SubscribedUser!
    $std_ids: [ID!]!
    $inst_id: ID!
  ) {
    AddIndividualStdMessageWithTags(
      token: $token
      input: $input
      user_details: $user_details
      std_ids: $std_ids
      inst_id: $inst_id
    )
  }
`;
export const AddChannelMessageWithTags = gql`
  mutation AddChannelMessageWithTags(
    $token: String!
    $input: CreateChannelMessageInput!
    $user_details: SubscribedUser!
    $student_id: ID!
  ) {
    AddChannelMessageWithTags(
      token: $token
      input: $input
      user_details: $user_details
      student_id: $student_id
    ) {
      id
      msg_date
      msg_expiry_date
      msg_header
      msg_content
      msg_media_type
      msg_media_content
      created_at
      sent_user_type
      sent_user_id
      msg_channel_id
      inst_id
    }
  }
`;
export const UpdateChannelMessage = gql`
  mutation UpdateChannelMessage(
    $token: String!
    $msg_id: ID!
    $input: UpdateChannelMessageInput!
    $user_details: SubscribedUser!
  ) {
    UpdateChannelMessage(
      token: $token
      input: $input
      msg_id: $msg_id
      user_details: $user_details
    ) {
      id
      msg_content
      msg_media_content
      msg_media_type
    }
  }
`;

export const UpdateChannel = gql`
  mutation UpdateChannel(
    $token: String!
    $msg_channel_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgChannelMasterInput!
  ) {
    UpdateChannel(
      token: $token
      msg_channel_id: $msg_channel_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
