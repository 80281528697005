import { gql } from "@apollo/client";

export const GetMsgEmp = gql`
  query GetMsgEmp(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: MsgEmpMasterOrder
    $name: String
  ) {
    GetMsgEmp(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      where: { or: [{ empNameContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          emp_name
          emp_sex
          emp_dob
          emp_mobile
          emp_email
          emp_status
          last_pwd_change_date
          firebase_id
          is_login_created
          auth_id
          inst_id
          is_global_user
        }
      }
    }
  }
`;
export const GetPayRollMasterData = gql`
  query GetPayRollMasterData($token: String!, $inst_id: ID!) {
    GetPayRollMasterData(token: $token, inst_id: $inst_id) {
      pr_dept_details {
        id
        dept_desc
        dept_idx
      }
      pr_designation_details {
        id
        designation_desc
        designation_idx
      }
      pr_category_details {
        id
        category_desc
        category_idx
      }
      pr_job_type_details {
        id
        job_type_desc
        job_type_idx
      }
      pr_grade_details {
        id
        grade_desc
        grade_idx
      }
    }
  }
`;

export const MsgEmpMaster = gql`
  query MsgEmpMaster($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on MsgEmpMaster {
        id
        emp_name
        emp_sex
        emp_dob
        emp_mobile
        emp_email
        emp_status
        last_pwd_change_date
        firebase_id
        is_login_created
        can_send_feeds
        can_send_announcement
        can_send_bd_msg
        auth_id
        inst_id
        is_global_user
      }
    }
  }
`;
export const EmpDetailsById = gql`
  query nodes($token: String!, $ids: [ID!]!) {
    nodes(token: $token, ids: $ids) {
      ... on MsgEmpMaster {
        id
        emp_name
        emp_sex
        emp_dob
        emp_mobile
        emp_email
        emp_status
        last_pwd_change_date
        firebase_id
        is_login_created
        can_send_feeds
        can_send_announcement
        can_send_bd_msg
        auth_id
        inst_id
        is_global_user
      }
    }
  }
`;
