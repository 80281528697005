import React, { useContext, useEffect, useState } from "react";
import Announcements from "./Announcements";
import Navbar from "../../../../Layouts/Navbar";
import { EMPTY_STRING, licenseTypes } from "../../../../../utils/constants";
import { Title } from "../../../../../stories/Title/Title";
import StudentDetails from "./Details";
import MessageList from "./MessageList";
import useChannelConfigs from "../../../Chat/CustomHooks/useChannelConfigs";

import useChannelDetails from "../../../../../customhooks/useChannelDetails";
import { AppContext } from "../../../../../context/context";
import { responseType } from "../../../../../utils/Types";
import { ChannelQueryType } from "../../../../../utils/Enum.types";
import { Label } from "../../../../../stories/Label/Label";
export interface channelOptionsType extends responseType {
  channelQueryType: ChannelQueryType;
}
const Index = () => {
  const {
    enableAnnouncement,
    enableClassLevelChannels,
    enableIndividualChannels,
  } = useChannelConfigs();
  const { channelDetails } = useChannelDetails();
  console.log(channelDetails, "channelDetails");

  const { state } = useContext(AppContext);

  const [selectedChannel, setSelectedChannel] =
    useState<channelOptionsType | null>(null);
  const [channelOptions, setChannelOptions] = useState<channelOptionsType[]>(
    []
  );

  useEffect(
    () => {
      if (
        channelDetails.data &&
        !channelDetails.loading &&
        channelDetails.data.GetChannelSubscribed.length
      ) {
        const length = channelDetails.data.GetChannelSubscribed.length;
        setChannelOptions(
          channelDetails.data.GetChannelSubscribed.map((channel) => ({
            label: channel.channel_name,
            value: channel.id,
            channelQueryType:
              channel.channel_name === state.studentId.toString()
                ? ChannelQueryType.INDIVIDUAL
                : channel.channel_name === "My Campus Activities"
                ? ChannelQueryType.MYCAMPUSFEED
                : channel.channel_name === "Announcements"
                ? ChannelQueryType.ANNOUNCEMENTS
                : ChannelQueryType.ENTRYLEVEL,
          }))
        );

        setSelectedChannel({
          label:
            channelDetails.data.GetChannelSubscribed[length - 1].channel_name,
          value: channelDetails.data.GetChannelSubscribed[length - 1].id,
          channelQueryType: ChannelQueryType.ENTRYLEVEL,
        });
      }
    },
    // eslint-disable-next-line
    [channelDetails.data, channelDetails.loading]
  );

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="std-dashboard">
        <Title>Student Dashboard</Title>
        <div className="std-dashboard__frame ">
          <div className=" std-dashboard__ls ">
            <ul className="std-dashboard__ls--ul">
              <Title>List Of Channels</Title>
              {channelDetails.data
                ? channelOptions.map((channel, index) => {
                    if (channel.label === state.studentId.toString())
                      return (
                        <li
                          key={index}
                          onClick={() => setSelectedChannel(channel)}
                        >
                          Me
                        </li>
                      );
                    return (
                      <li
                        key={index}
                        onClick={() => setSelectedChannel(channel)}
                      >
                        {channel.label}
                      </li>
                    );
                  })
                : channelDetails.error
                ? channelDetails.error.message
                : EMPTY_STRING}
            </ul>
          </div>

          <div className=" std-dashboard__md">
            {enableClassLevelChannels || enableIndividualChannels ? (
              <MessageList channelOptions={selectedChannel} />
            ) : null}
          </div>
          <div className="std-dashboard__rs">
            {enableAnnouncement ? (
              <div className="std-dashboard__rs--top">
                <Announcements />
              </div>
            ) : null}

            <div
              className={
                enableAnnouncement ? "std-dashboard__rs--bottom" : "h100"
              }
            >
              <StudentDetails />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
